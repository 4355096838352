import React, { useEffect } from "react";
import RiskThreatTable from "./RiskThreatTable";
import RiskVulnerabilityTable from "./RiskVulnerabilityTable";
import RiskMitigationTable from "./RiskMitigationTable";
import RiskPilotTable from "./RiskPilotTable";
import {useSelector} from "react-redux";

export const RiskPilotTableSelector = ({
  tableData,
  tableView,
  currentPage,
  recordsPerPage,
  setCurrentPage,
  isEditMode,
}) => {

  const filterData = useSelector((state) => state.riskPilotReducer.filters);

  const renderRiskTable = () => {
    return (
      <RiskPilotTable
        tableData={tableData}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        setCurrentPage={setCurrentPage}
        isEditMode={isEditMode}
      />
    );
  };


  const renderThreatTable = () => {
    return (
      <RiskThreatTable
        tableData={tableData}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        setCurrentPage={setCurrentPage}
        isEditMode={isEditMode}
      />
    );
  };

  const renderVulnerabilityTable = () => {
    return (
      <RiskVulnerabilityTable
        tableData={tableData}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        setCurrentPage={setCurrentPage}
        isEditMode={isEditMode}
      />
    );
  };

  const renderMitigationTable = () => {
    return (
      <RiskMitigationTable
        tableData={tableData}
        currentPage={currentPage}
        recordsPerPage={recordsPerPage}
        setCurrentPage={setCurrentPage}
        isEditMode={isEditMode}
      />
    );
  };

  const renderSelectedTable = () => {
    if((tableData && tableData.length > 0 ) || filterData.length > 0) {
      if (tableView === "RISK_VIEW") {
        return renderRiskTable();
      } else if (tableView === "THREAT_VIEW") {
        return renderThreatTable();
      } else if (tableView === "VULNERABILITY_VIEW") {
        return renderVulnerabilityTable();
      } else if (tableView === "MITIGATION_VIEW") {
        return renderMitigationTable();
      }
    }
  }
  return <div>{renderSelectedTable()}</div>;
};
