import { put, takeEvery, select, call } from "redux-saga/effects";
import {
  DELETE_IPS_REQUEST,
  FETCH_IPS_REQUEST,
  GET_LOCATION_DATA,
  GET_LOCATION_TABLE_DATA,
  LOCATION_DATA_API,
  locationTableDataApi,
  SET_ALL_IPS,
  SET_IP_CONFIG_TOTAL_COUNT,
  SET_LOCATION_DATA,
  SET_LOCATION_LOADER,
  SET_LOCATION_SNACKBAR_MESSAGE,
  SET_LOCATION_TABLE_DATA,
} from "./locationConstants";
import { getAxiosHeaders, getService } from "../../../library/RestAPI";
import { MDR_TENENT_IP_GEO_LOCATION_URL } from "../../../redux/constants/constants";

function* getLocationData() {
  yield put({ type: SET_LOCATION_LOADER, payload: true });
  const options = {
    method: "GET",
    url: LOCATION_DATA_API,
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      const locationData = res?.data;
      const formattedLocationData = locationData.map((item) => ({
        position: [item.latitude, item.longitude],
        title: `${item.city}, ${item.country}`,
        totalActivity: item.totalActivity,
        dataSources: item.dataSources,
        mostAccessed: {
          type: item.mostAccessed.dataSourceType,
          name: item.mostAccessed.aliasName,
          accessCount: item.mostAccessed.accessCount,
        },
        leastAccessed: {
          type: item.leastAccessed.dataSourceType,
          name: item.leastAccessed.aliasName,
          accessCount: item.leastAccessed.accessCount,
        },
        alerts: {
          alert_count_high: item.alerts.HIGH || 0,
          alert_count_medium: item.alerts.MEDIUM || 0,
          alert_count_low: item.alerts.LOW || 0,
        },
        usersWithAlerts: {
          total: item.usersWithAlerts.total_count,
          HIGH: item.usersWithAlerts.HIGH,
          MEDIUM: item.usersWithAlerts.MEDIUM,
          LOW: item.usersWithAlerts.LOW,
        },
      }));
      yield put({ type: SET_LOCATION_DATA, payload: formattedLocationData });
      yield put({ type: SET_LOCATION_LOADER, payload: false });
    } else {
      const errorMessage = "Failed to fetching Data";
      yield put({ type: SET_LOCATION_LOADER, payload: false });
      yield put({ type: SET_LOCATION_SNACKBAR_MESSAGE, payload: errorMessage });
    }
  } catch (err) {
    yield put({ type: SET_LOCATION_LOADER, payload: false });
    yield put({ type: SET_LOCATION_SNACKBAR_MESSAGE, payload: err });
  }
}

function* getLocationTableData(action) {
  yield put({ type: SET_LOCATION_LOADER, payload: true });
  const lat = action.payload[0];
  const long = action.payload[1];
  const options = {
    method: "GET",
    url: locationTableDataApi(lat, long),
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      const locationTableData = res?.data;
      yield put({ type: SET_LOCATION_TABLE_DATA, payload: locationTableData });
      yield put({ type: SET_LOCATION_LOADER, payload: false });
    } else {
      const errorMessage = "Error fetching Data";
      yield put({ type: SET_LOCATION_LOADER, payload: false });
      yield put({ type: SET_LOCATION_SNACKBAR_MESSAGE, payload: errorMessage });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: SET_LOCATION_LOADER, payload: false });
    yield put({ type: SET_LOCATION_SNACKBAR_MESSAGE, payload: error });
  }
}

function* fetchIPsSaga(action) {
  yield put({ type: SET_LOCATION_LOADER, payload: true });

  const { recordsPerPage, currentPage } = action.payload;
  const url = `${MDR_TENENT_IP_GEO_LOCATION_URL}?size=${recordsPerPage}&page=${currentPage}`;

  try {
    const res = yield call(getService, {
      method: "GET",
      url,
      headers: getAxiosHeaders(true),
    });

    if (res.status === 200) {
      const allIps = res?.data?.content;
      const ipTotalCount = res?.data?.totalElements;
      yield put({ type: SET_ALL_IPS, payload: allIps});
      yield put ({ type: SET_IP_CONFIG_TOTAL_COUNT, payload: ipTotalCount});
      yield put ({ type: SET_LOCATION_LOADER, payload: false});
    } else {
      console.log("Failed to fetch IPs");
      yield put ({ type: SET_LOCATION_LOADER, payload: false});
    }
  } catch (error) {
    console.log("Failed to fetch IPs");
    yield put ({ type: SET_LOCATION_LOADER, payload: false});
  }
}

function* deleteIPsSaga(action) {
  try {
    yield put({ type: SET_LOCATION_LOADER, payload: true });
    const { selectedList, recordsPerPage, currentPage } = action.payload;
    const url = `${MDR_TENENT_IP_GEO_LOCATION_URL}/${selectedList.join(",")}`;
    const deleteRes = yield call(getService, {
      method: "DELETE",
      url,
      headers: getAxiosHeaders(true),
    });

    if (deleteRes.status !== 200) {
      throw new Error("Failed to delete IPs");
    }

    // Reuse fetchIPsSaga to get the updated list
    yield call(fetchIPsSaga, { payload: { recordsPerPage, currentPage } });
  } catch (error) {
    console.error(error.message);
  } finally {
    yield put({ type: SET_LOCATION_LOADER, payload: false });
  }
}



function* locationSaga() {
  yield takeEvery(GET_LOCATION_DATA, getLocationData);
  yield takeEvery(GET_LOCATION_TABLE_DATA, getLocationTableData);
  yield takeEvery(FETCH_IPS_REQUEST, fetchIPsSaga);
  yield takeEvery(DELETE_IPS_REQUEST, deleteIPsSaga);
}
export default locationSaga;
