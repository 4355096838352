import React from "react";
import ChartWrapper from "../../../common/Dashboard/Chart";
import { viewDataMap } from "../../../../redux/constants/constants";
import { pathWithPermission } from "../../../common/utilities";
import {
  mitigationOwnerLookup,
  riskTypeIcons,
  riskTypeLookup,
  SET_FILTER_RR,
} from "../riskPilotConstant";
import { renderNoData } from "../../../common/Dashboard/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";

// Lookup table for severity levels
const severityLevels = {
  CRITICAL: 4,
  HIGH: 3,
  MEDIUM: 2,
  LOW: 1,
};

const severityLookup = {
  CRITICAL: "Critical",
  HIGH: "High",
  MEDIUM: "Medium",
  LOW: "Low",
};

// Icons for each status
const statusIcons = {
  TODO: "/riskPilotIcons/ToDoWhite.svg",
  OPEN: "/riskPilotIcons/openWhite.svg",
  IN_PROGRESS: "/riskPilotIcons/InProgressWhite.svg",
  CLOSED: "/riskPilotIcons/closedWhite.svg",
};

const isCountNonZero = (counts, status) => counts[status] !== 0;

const filterNav = (riskType, severity, status, history, dispatch) => {
  // Convert the input filters into an array of objects
  const filterData = [
    riskType && { riskType: { operator: "equals", value: riskType } },
    severity && { severity: { operator: "equals", value: severity } },
    status && { status: { operator: "equals", value: status } },
  ].filter(Boolean); // Remove any `null` or `undefined` values

  dispatch({ type: SET_FILTER_RR, payload: filterData });

  history.push({ pathname: "/gov/RiskPilot" });
};

const RiskCategoryWidget = (cubejsApi, name, query) => {
  const history = useHistory();
  const dispatch  = useDispatch();

  // Function to render legends and chart
  const renderLegends = (resultSet) => {
    const data = resultSet.loadResponse.results[0].data;

    // Handle no data scenarios
    if (!data || data.length === 0) {
      return renderNoData("22%");
    }

    // Prepare data by combining counts and determining severity
    const aggregatedData = data.reduce((acc, row) => {
      const riskType = row["RiskMitigationStatus.riskType"];
      const severity = row["RiskMitigationStatus.severity"];
      const statusCounts = {
        TODO: parseInt(row["RiskMitigationStatus.toDoCount"], 10) || 0,
        OPEN: parseInt(row["RiskMitigationStatus.openCount"], 10) || 0,
        IN_PROGRESS:
          parseInt(row["RiskMitigationStatus.inProgressCount"], 10) || 0,
        CLOSED: parseInt(row["RiskMitigationStatus.closedCount"], 10) || 0,
      };

      // Initialize if the riskType doesn't exist in acc
      if (!acc[riskType]) {
        acc[riskType] = {
          severity: severity,
          counts: {
            TODO: 0,
            OPEN: 0,
            IN_PROGRESS: 0,
            CLOSED: 0,
          },
        };
      }

      // Combine counts for each status
      Object.keys(statusCounts).forEach((status) => {
        acc[riskType].counts[status] += statusCounts[status];
      });

      // Determine highest severity dynamically
      if (severityLevels[severity] > severityLevels[acc[riskType].severity]) {
        acc[riskType].severity = severity;
      }

      return acc;
    }, {});

    // Convert aggregatedData to an array of objects for easier mapping
    const aggregatedArray = Object.keys(aggregatedData).map((riskType) => ({
      riskType: riskType,
      severity: aggregatedData[riskType].severity,
      counts: aggregatedData[riskType].counts,
    }));

    const assignClassNames = (counts, status) => {
      return isCountNonZero(counts, status)
        ? `mitigationStatusCount ${status} filters`
        : `mitigationStatusCount ${status}`;
    };

    return (
      <React.Fragment>
        <hr className="threatLevelChartLine" />
        <div className="barChartParentRiskContainer riskCategories">
          <table className="riskTable">
            <thead>
              <tr>
                <th>Type</th>
                <th>Level</th>
                <th>Mitigation Status</th>
              </tr>
            </thead>
            <tbody>
              {aggregatedArray.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="riskTypeIconsContainer dashboardIcons">
                      <img
                        src={riskTypeIcons[item.riskType]}
                        alt={severityLookup[item.riskType]}
                        style={{ width: "6%" }}
                      />
                      {riskTypeLookup[item.riskType] || item.riskType}
                    </div>
                  </td>
                  <td className={`level ${item.severity.toLowerCase()}`}>
                    {severityLookup[item.severity] || item.severity}
                  </td>
                  <td>
                    <div className="mitigationCountChildContainer riskCategories">
                      {/* Render each status with icon and count */}
                      {Object.keys(item.counts).map((status, idx) => (
                        <div
                          key={idx}
                          className={assignClassNames(item.counts, status)}
                          title={status}
                          onClick={() =>
                            isCountNonZero(item.counts, status)
                              ? filterNav(
                                  item.riskType,
                                  item.severity,
                                  status,
                                  history,
                                  dispatch
                                )
                              : null
                          }
                        >
                          <div className="statusCount">
                            <img src={statusIcons[status]} alt={status} />
                            {item.counts[status]}
                          </div>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="threatPieChart riskCategory">
      {/* Use ChartWrapper to integrate with Cube.js API */}
      <ChartWrapper
        cubejsApi={cubejsApi}
        title={name}
        query={query}
        render={renderLegends}
      />
    </div>
  );
};

export default RiskCategoryWidget;
