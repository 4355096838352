import React, { useEffect, useRef } from "react";
import { RenderFormFields, renderFormWithIcon, renderHeading, renderTextArea } from "./riskPilotHelperComp";
import "./RiskGovernance.scss";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@material-ui/core";
import {
  handleDeleteMitigation,
  handleMitigationOwnerChange,
  handleMitigationPriorityChange,
  handleMitigationStatusChange,
  handleMitigationTextChange,
} from "./riskPilotUtilities";
import DeleteRRIcon from "../../../assets/icons/DeleteRR.svg";
import arrowDropUpCircle from "../../../assets/icons/arrowDropUpCircle.svg";
import arrowDropDownCircle from "../../../assets/icons/arrowDropDownCircle.svg";
import { useDispatch, useSelector } from "react-redux";
import { RISK_PILOT_LEVELS, RISK_PILOT_MITIGATION_STATUS } from "./riskPilotConstant";

const RiskPilotMitigation = ({
  vulnerabilityId,
  vulnerabilityName,
  threatId,
  threatName,
  mitigation,
  mitigationExpanded,
  handleMitigationAccordionChange,
  riskOwners,
}) => {
  const mitigationRef = useRef(null);
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.riskPilotReducer.loader);
  const renderMitigation = (threatId, vulnerabilityId, mitigation) => (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
      ref={mitigationRef}
    >
      <div style={{ width: "100%" }}>
        <div className="riskContainer">
          {renderHeading("Mitigation")}
          <div className="riskRegisterTextArea">
            {renderTextArea(
              mitigation.mitigationText,
              (newText) =>
                handleMitigationTextChange(
                  dispatch,
                  threatId,
                  vulnerabilityId,
                  mitigation.id,
                  newText
                ),
              "Mitigation"
            )}
          </div>
        </div>
      </div>
      <div className="mitigationPriorityContainer">
        <div className="riskContainer" style={{ marginLeft: "2%" }}>
          {renderHeading("Mitigation Priority")}
          <div className="riskSelect impact">
            <RenderFormFields
              options={RISK_PILOT_LEVELS}
              value={mitigation.mitigationPriority}
              setValue={(newSeverity) =>
                handleMitigationPriorityChange(
                  dispatch,
                  threatId,
                  vulnerabilityId,
                  mitigation.id,
                  newSeverity
                )
              }
              severityValue
            />
          </div>
        </div>
        <div className="riskContainer" style={{ marginLeft: "15px" }}>
          {renderHeading("Mitigation Status")}
          <div className="riskSelect impact" style={{ width: "200px" }}>
            {renderFormWithIcon(
              RISK_PILOT_MITIGATION_STATUS,
              mitigation.mitigationStatus,
              (newStatus) =>
                handleMitigationStatusChange(
                  dispatch,
                  threatId,
                  vulnerabilityId,
                  mitigation.id,
                  newStatus
                ),
              "risk"
            )}
          </div>
        </div>
        <div className="riskContainer">
          {renderHeading("Ownership")}
          <div className="riskSelect">
            <RenderFormFields
              options={riskOwners}
              value={mitigation.mitigationOwner}
              setValue={(newOwner) =>
                handleMitigationOwnerChange(
                  dispatch,
                  threatId,
                  vulnerabilityId,
                  mitigation.id,
                  newOwner
                )
              }
              loc="risk"
              owner
            />
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (mitigationRef.current && !loader) {
      mitigationRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [mitigationExpanded, loader]);  

  return (
    <div className="mitigationContainer" style={{ background: "#D8B6EB" }}>
      <Accordion
        key={mitigation.id}
        expanded={mitigationExpanded[mitigation.id]}
        onChange={() => handleMitigationAccordionChange(mitigation.id)}
      >
        <AccordionSummary>
          <Typography className="riskThreatHeaderTypography">
            <div className="riskThreatHeaderContainer">
              <div className="riskGeneralStyle">
                {mitigationExpanded[mitigation.id] ? (
                  <img src={arrowDropUpCircle} alt="arrow up" />
                ) : (
                  <img src={arrowDropDownCircle} alt="arrow down" />
                )}
                <div className="riskMultiTitleContainer">
                  <img
                    className="multiThreatIconCustomSizes threatMitigationIcon"
                    src="/riskPilotIcons/ThreatIcon.svg"
                  />
                  <span className="secondTitle">{`Threat: ${threatName}`}</span>
                  <span className="riskSummaryInfo">{">"}</span>
                  <img
                    className="multiThreatIconCustomSizes threatMitigationIcon"
                    src="/riskPilotIcons/VulnerbilityIcon.svg"
                  />
                  <span className="secondTitle">{`Vulnerability: ${vulnerabilityName}`}</span>
                  <span className="riskSummaryInfo">{">"}</span>
                  <img
                    className="multiThreatIconCustomSizes mitigationIcon"
                    src="/riskPilotIcons/MitigationIcon.svg"
                  />
                  <div className="riskRegisterHeading threat">{`Mitigation: ${mitigation.mitigationText}`}</div>
                </div>
              </div>
              <div>
                <Button
                  color="secondary"
                  onClick={() =>
                    handleDeleteMitigation(
                      dispatch,
                      mitigation.id,
                      vulnerabilityId,
                      threatId
                    )
                  }
                >
                  <img src={DeleteRRIcon} alt="delete icon" />
                </Button>
              </div>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="mitigationChildContainer">
            {renderMitigation(threatId, vulnerabilityId, mitigation)}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default RiskPilotMitigation;
