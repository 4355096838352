import {
  CLEAR_FILTERS_RP,
  CLEAR_RISK_PILOT_FILES,
  MITIGATION_STATUS_DATA,
  RISK_STATUS_DATA,
  CLEAR_RISK_PILOT_ROW_DETAILS,
  THREAT_STATUS_DATA,
  CLEAR_RP_DATA,
  RISK_PILOT_ROW_DETAILS,
  SET_ALL_RISK_PILOT_FILES,
  VULNERABILITY_STATUS_DATA,
  SET_ALL_RISK_TYPES,
  SET_FILTER_RR,
  SET_FILTER_TRIGGERED,
  SET_IS_RISK_CREATED,
  SET_RISK_OWNERS,
  SET_RP_LOADER,
  TOTAL_RP_ROW_COUNT,
  RISK_SNACKBAR_MESSAGE,
  SET_RISK_SUMMARY_DETAILS,
  SELECTED_ROW_ID,
} from "../riskPilotConstant";

const initialState = {
  riskTypes: [],
  riskOwners: [],
  allRiskPilotFiles: [],
  riskPilotRowDetails: null,
  totalRowCount: 0,
  isRiskCreated: "",
  loader: false,
  filters: [],
  isFiltersSelected: false,
  riskStatusData: {},
  threatStatusData: {},
  vulnerabilityStatusData: {},
  mitigationStatusData: {},
  snackbarMessage: "",
  riskSummaryData: {},
  selectedRowId: {
    threatId: "",
    vulnerabilityId: "",
    MitigationId: ""
  }
};

export const riskPilotReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_RISK_TYPES: {
      return {
        ...state,
        riskTypes: action.payload,
      };
    }
    case SET_RISK_OWNERS: {
      return {
        ...state,
        riskOwners: action.payload,
      };
    }
    case SET_ALL_RISK_PILOT_FILES: {
      return {
        ...state,
        allRiskPilotFiles: action.payload,
      };
    }
    case SET_RP_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }
    case RISK_PILOT_ROW_DETAILS: {
      return {
        ...state,
        riskPilotRowDetails: action.payload,
      };
    }
    case SET_IS_RISK_CREATED: {
      return {
        ...state,
        isRiskCreated: action.payload,
      };
    }
    case SET_FILTER_RR: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case TOTAL_RP_ROW_COUNT: {
      return {
        ...state,
        totalRowCount: action.payload,
      };
    }
    case SET_FILTER_TRIGGERED: {
      return {
        ...state,
        isFiltersSelected: action.payload,
      };
    }
    case RISK_STATUS_DATA: {
      return {
        ...state,
        riskStatusData: action.payload,
      };
    }
    case THREAT_STATUS_DATA: {
      return {
        ...state,
        threatStatusData: action.payload,
      };
    }
    case VULNERABILITY_STATUS_DATA: {
      return {
        ...state,
        vulnerabilityStatusData: action.payload,
      };
    }
    case MITIGATION_STATUS_DATA: {
      return {
        ...state,
        mitigationStatusData: action.payload,
      };
    }
    case RISK_SNACKBAR_MESSAGE: {
      return {
        ...state,
        snackbarMessage: action.payload
      }
    }
    case SET_RISK_SUMMARY_DETAILS: {
      return {
        ...state,
        riskSummaryData: action.payload
      }
    }
    case SELECTED_ROW_ID: {
      return {
        ...state,
        selectedRowId: action.payload
      }
    }
    case CLEAR_RP_DATA: {
      return {
        ...state,
        riskTypes: [],
        riskOwners: [],
        loader: false,
        totalRowCount: 0,
        isFiltersSelected: false,
        riskSummaryData: {},
        selectedRowId: {
          threatId: "",
          vulnerabilityId: "",
          mitigationId: "",
        }
      };
    }
    case CLEAR_RISK_PILOT_ROW_DETAILS: {
      return {
        ...state,
        riskPilotRowDetails: null,
      };
    }
    case CLEAR_FILTERS_RP: {
      return {
        ...state,
        filters: [],
      };
    }
    case CLEAR_RISK_PILOT_FILES: {
      return {
        ...state,
        allRiskPilotFiles: [],
      };
    }
    default:
      return state;
  }
};
