export const RiskThreatHeadCells = (
    renderRiskRegisterID,
    renderRiskRegisterName,
    renderRiskSeverity,
    renderThreat,
    renderThreatRisk,
    renderVulnerabilities,
    renderMitigations,
    renderRegisterAction
  ) => {
  
    return [
      {
        title: "Risk ID",
        style: { width: "10%", cursor: "default" },
        rowBodyCellStyle: { width: "10%", cursor: "default" },
        render: (rowData) => {
          return <>{renderRiskRegisterID(rowData.riskId)}</>;
        },
      },
      {
        title: "Risk",
        style: { width: "15%" },
        rowBodyCellStyle: { width: "15%", cursor: "default" },
        render: (rowData) => {
          return <>{renderRiskRegisterName(rowData.riskType)}</>;
        },
      },
      {
        title: "Risk Severity",
        style: { width: "10%" },
        rowBodyCellStyle: { width: "10%", cursor: "default" },
        render: (rowData) => {
          return <>{renderRiskSeverity(rowData.severity)}</>;
        },
      },
      {
        title: "Threat",
        style: { width: "15%", textAlign: "left"},
        rowBodyCellStyle: { width: "15%", cursor: "default", textAlign: "left" },
        render: (rowData) => {
          return <>{renderThreat(rowData.threat)}</>;
        },
      },
      {
        title: "Threat Risk",
        style: { width: "13%", textAlign: "center !important"},
        rowBodyCellStyle: { width: "13%", cursor: "default", textAlign: "center !important" },
        render: (rowData) => {
          return <>{renderThreatRisk(rowData.threatPriority)}</>
        }
      },
      {
        title: "Vulnerabilities",
        style: { width: "10%", textAlign: "center"},
        rowBodyCellStyle: { width: "10%", cursor: "default", textAlign: "center" },
        render: (rowData) => {
          return <>{renderVulnerabilities(rowData.vulnerabilityCount)}</>
        }
      },
      {
        title: "No Of Mitigations",
        style: { width: "10%", textAlign: "center"},
        rowBodyCellStyle: { width: "10%",  cursor: "default", textAlign: "center" },
        render: (rowData) => {
          return <>{renderMitigations(rowData.mitigationCount, rowData.toDoCount, rowData.openCount, rowData.inProgressCount,rowData.closedCount )}</>;
        },
      },
      {
        title: "Action",
        style: { width: "10%", textAlign: "right"},
        rowBodyCellStyle: { width: "10%", textAlign: "right", cursor: "pointer" },
        render: (rowData) => {
          return <>{renderRegisterAction(rowData.riskId, rowData.threatId)}</>;
        },
      },
    ];
};

export const RiskVulnerabilityHeadCells = (
    renderRiskRegisterID,
    renderRiskRegisterName,
    renderRiskSeverity,
    renderThreat,
    renderThreatRisk,
    renderVulnerability,
    renderMitigations,
    renderRegisterAction
  ) => {
  
    return [
      {
        title: "Risk ID",
        style: { width: "10%", cursor: "default" },
        rowBodyCellStyle: { width: "10%", cursor: "default" },
        render: (rowData) => {
          return <>{renderRiskRegisterID(rowData.riskId)}</>;
        },
      },
      {
        title: "Risk",
        style: { width: "15%" },
        rowBodyCellStyle: { width: "15%", cursor: "default" },
        render: (rowData) => {
          return <>{renderRiskRegisterName(rowData.riskType)}</>;
        },
      },
      {
        title: "Risk Severity",
        style: { width: "10%" },
        rowBodyCellStyle: { width: "10%", cursor: "default" },
        render: (rowData) => {
          return <>{renderRiskSeverity(rowData.severity)}</>;
        },
      },
      {
        title: "Threat",
        style: { width: "15%", textAlign: "left"},
        rowBodyCellStyle: { width: "15%", cursor: "default", textAlign: "left" },
        render: (rowData) => {
          return <>{renderThreat(rowData.threat)}</>;
        },
      },
      {
        title: "Threat Risk",
        style: { width: "10%", textAlign: "center !important"},
        rowBodyCellStyle: { width: "10%", cursor: "default", textAlign: "center !important" },
        render: (rowData) => {
          return <>{renderThreatRisk(rowData.threatPriority)}</>
        }
      },
      {
        title: "Vulnerability",
        style: { width: "20%", textAlign: "left"},
        rowBodyCellStyle: { width: "20%", cursor: "default", textAlign: "left" },
        render: (rowData) => {
          return <>{renderVulnerability(rowData.vulnerability
          )}</>
        }
      },
      {
        title: "No Of Mitigations",
        style: { width: "10%", textAlign: "center"},
        rowBodyCellStyle: { width: "10%",  cursor: "default", textAlign: "center" },
        render: (rowData) => {
          return <>{renderMitigations(rowData.mitigationCount, rowData.toDoCount, rowData.openCount, rowData.inProgressCount,rowData.closedCount )}</>;
        },
      },
      {
        title: "Action",
        style: { width: "10%", textAlign: "right"},
        rowBodyCellStyle: { width: "10%", textAlign: "right", cursor: "pointer" },
        render: (rowData) => {
          return <>{renderRegisterAction(rowData.riskId, rowData.vulnerabilityId, rowData.threatId)}</>;
        },
      },
    ];
};

export const RiskMitigationHeadCells = (
  renderRiskRegisterID,
  renderRiskRegisterName,
  renderRiskSeverity,
  renderThreat,
  renderVulnerability,
  renderMitigation,
  renderMitigationPriority,
  renderMitigationStatus,
  renderMitigationOwner,
  renderRegisterAction
) => {

  return [
    {
      title: "Risk ID",
      style: { width: "10%", cursor: "default" },
      rowBodyCellStyle: { width: "10%", cursor: "default" },
      render: (rowData) => {
        return <>{renderRiskRegisterID(rowData.riskId)}</>;
      },
    },
    {
      title: "Risk",
      style: { width: "14%" },
      rowBodyCellStyle: { width: "14%", cursor: "default" },
      render: (rowData) => {
        return <>{renderRiskRegisterName(rowData.riskType)}</>;
      },
    },
    {
      title: "Risk Severity",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", cursor: "default" },
      render: (rowData) => {
        return <>{renderRiskSeverity(rowData.severity)}</>;
      },
    },
    {
      title: "Threat",
      style: { width: "14%", textAlign: "center"},
      rowBodyCellStyle: { width: "14%", cursor: "default", textAlign: "center" },
      render: (rowData) => {
        return <>{renderThreat(rowData.threat)}</>;
      },
    },
    {
      title: "Vulnerability",
      style: { width: "19%", textAlign: "center"},
      rowBodyCellStyle: { width: "19%", cursor: "default", textAlign: "center" },
      render: (rowData) => {
        return <>{renderVulnerability(rowData.vulnerability)}</>
      }
    },
    {
      title: "Mitigation",
      style: { width: "19%", textAlign: "center"},
      rowBodyCellStyle: { width: "19%", cursor: "default", textAlign: "center" },
      render: (rowData) => {
        return <>{renderMitigation(rowData.mitigation)}</>
      }
    },
    {
      title: "Priority",
      style: { width: "10%" },
      rowBodyCellStyle: { width: "10%", cursor: "default" },
      render: (rowData) => {
        return <>{renderMitigationPriority(rowData.priority)}</>;
      },
    },
    {
      title: "Status",
      style: { width: "13%", textAlign: "center"},
      rowBodyCellStyle: { width: "13%",  cursor: "default", textAlign: "center" },
      render: (rowData) => {
        return <>{renderMitigationStatus(rowData.status)}</>;
      },
    },
    {
      title: "Owner",
      style: { width: "10%", textAlign: "center"},
      rowBodyCellStyle: { width: "10%",  cursor: "default", textAlign: "center" },
      render: (rowData) => {
        return <>{renderMitigationOwner(rowData.owner)}</>;
      },
    },
    {
      title: "Action",
      style: { width: "10%", textAlign: "right"},
      rowBodyCellStyle: { width: "10%", textAlign: "right", cursor: "pointer" },
      render: (rowData) => {
        return <>{renderRegisterAction(rowData.riskId, rowData.mitigationId, rowData.vulnerabilityId, rowData.threatId)}</>;
      },
    },
  ];
};

