import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { cloneDeep } from "lodash";
// import "../../../assets/scss/BackOfficeStyles/Common.scss";
import "./MDRSettings.scss";
import { getAxiosHeaders, getService } from "../../../library/RestAPI";
import { DeleteButtonForMDR, AddMDRConfig, AddDDVConfig } from "../../common/buttons";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  MDRAccoutIdConfigFields,
  MDRIpConfigFields,
  MDRIpConfigLoc,
  MDRAccountIdConfigLoc,
  MDRAccountIdState,
  MDRIpConfigState,
  MDR_TENENT_IP_GEO_LOCATION_URL,
  MDR_TENENT_ACCOUNT_ID_LOCATION_URL,
  GET_ALL_COUNTRIES,
  GET_ALL_REGIONS,
  GET_ALL_CITIES,
  DDVIpconfigLoc,
} from "../../../redux/constants/constants";
import ManagementFooter from "../../views/BackOfficeGeneralContainer/ManagementFooter";
import Snackbar from "@material-ui/core/Snackbar";
import { Grid } from "@material-ui/core";
import MDRInputCommon from "./MDRInputCommon";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const MDRSettingsContent = () => {
  const classes = useStyles();
  const history = useHistory();
  const [openSuccess, setopenSuccess] = useState(false);
  const [openError, setopenError] = useState({ open: false, message: "" });
  const [errorStateArr, seterrorStateArr] = useState([]);

  const [inputFieldsNames, setInputFieldNames] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setopenSuccess(false);
    setopenError({ open: false, message: "" });
  };
  const [addFeild, setAddFeild] = useState([1]);
  // const [  input, setInput] = useState([]);
  // const [select, setSelect] = useState([]);
  // const [error, setError] = useState([]);
  const [state, setState] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentCountrySearchStr, setcurrentCountrySearchStr] = useState("");
  const [currentRegionSearchStr, setcurrentRegionSearchStr] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [regionsList, setregionsList] = useState([]);
  const [citiesList, setcitiesList] = useState([]);
  const [citiesTotalDataList, setcitiesTotalDataList] = useState([]);

  const inputHandler = (event, index1, inputFieldsNames, inputValue) => {
    //  errorsArr[index][property]
    setCurrentIndex(index1);

    let errorsArr = [...errorStateArr];
    let errItem = { ...errorStateArr[index1] };
    // 1. Make a shallow copy of the items
    let items = [...state];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...state[index1] };
    // 3. Replace the property you're intested in
    if (inputFieldsNames === "Account ID") {
      item.accountId = event.target.value;
      errItem.accountId = "";
    } else if (inputFieldsNames === "Country") {
      if (!inputValue) {
        item.city = "";
        item.region = "";
      }
      item.country = inputValue ? inputValue : event.target.innerHtml;
      errItem.country = "";
    } else if (inputFieldsNames === "Region") {
      if (!inputValue) {
        item.city = "";
      }
      item.region = inputValue ? inputValue : "";
      errItem.region = "";
    } else if (inputFieldsNames === "City") {
      item.city = inputValue ? inputValue : event.target.innerHtml;
      errItem.city = "";
    } else if (inputFieldsNames === "Environment") {
      item.environment = event.target.value;
      errItem.environment = "";
    } else if (inputFieldsNames === "IP") {
      item.ip4Address = event.target.value;
      errItem.ip4Address = "";
    } else if (inputFieldsNames === "Subnet Mask") {
      item.subnetMask = event.target.value;
      errItem.subnetMask = "";
    }

    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    items[index1] = item;
    errorsArr[index1] = errItem;
    // 5. Set the state to our new copy
    setState([...items]);
    seterrorStateArr(errorsArr);
  };
  const addLatitudeAndLongitude = (index, value) => {
    let errorsArr = [...errorStateArr];
    let errItem = { ...errorStateArr[index] };

    let items = [...state];
    // 2. Make a shallow copy of the item you want to mutate

    let newObj = { ...items[index] };
    if (value) {
      citiesTotalDataList.forEach((cityObj) => {
        if (cityObj.city === value) {
          newObj.latitude = cityObj.latitude;
          newObj.longitude = cityObj.longitude;
          newObj.city = value;
          errItem.city = "";
        }
      });
    } else {
      newObj.latitude = "";
      newObj.longitude = "";
      newObj.city = value;
    }

    items[index] = newObj;
    errorsArr[index] = errItem;

    seterrorStateArr(errorsArr);
    setState([...items]);
  };

  const settingsUpdatedLocation = useSelector(
    (state) => state.mdrSettingsReducer.updatedLocation
  );
  useEffect(() => {
    if (
      history.location.pathname === "/mdr/Settings/ipConfiguration" || 
      history.location.pathname === "/tenant/ipConfiguration"
      // settingsUpdatedLocation === MDRIpConfigLoc
    ) {
      setInputFieldNames([...MDRIpConfigFields]);
      setState([{ ...MDRIpConfigState }]);
      seterrorStateArr([{ ...MDRIpConfigState }]);
    } else if (
      history.location.pathname === "/mdr/Settings/accountIdConfiguration"
      // settingsUpdatedLocation === MDRAccountIdConfigLoc
    ) {
      setInputFieldNames([...MDRAccoutIdConfigFields]);
      setState([{ ...MDRAccountIdState }]);
      seterrorStateArr([{ ...MDRAccountIdState }]);
    }
  }, [settingsUpdatedLocation, history]);

  let headers = {};
  headers["Authorization"] = `Bearer ${localStorage.getItem("access_token")}`;
  headers["Tenant"] = "druvstar";
  useEffect(() => {
    const getCountryList = async () => {
      let url = GET_ALL_COUNTRIES(
        state[currentIndex] && state[currentIndex].country
      );
      const res = await getService({
        method: "GET",
        url: url,
        headers: headers,
      });
      if (res.status === 200) {
        setCountryList(res.data);
      }
    };

    getCountryList();
  }, []);

  useEffect(() => {
    //Todo: Steps to clear regions list and cities list after adding new input fields
    setregionsList([]);
    setcitiesList([]);

    const getRegionList = async () => {
      let url = GET_ALL_REGIONS(
        state[currentIndex] && state[currentIndex].country,
        ""
      );
      const res = await getService({
        method: "GET",
        url: url,
        headers: headers,
      });
      if (res.status === 200) {
        setregionsList(res.data);
      }
    };
    if (state[currentIndex] && state[currentIndex].country) {
      getRegionList();
    }
  }, [currentCountrySearchStr]);

  useEffect(() => {
    setcitiesList([]);
    const getCityList = async () => {
      let url = GET_ALL_CITIES(
        state[currentIndex] && state[currentIndex].country,
        state[currentIndex] && state[currentIndex].region
      );
      const res = await getService({
        method: "GET",
        url: url,
        headers: headers,
      });
      if (res.status === 200) {
        let resArr = res.data;
        let onlyCityData = resArr.map((obj) => {
          return obj.city;
        });
        let citiesArr = resArr.map((obj) => {
          return { ...obj };
        });
        setcitiesList([...onlyCityData]);
        setcitiesTotalDataList([...citiesArr]);
      }
    };
    if (
      state[currentIndex] &&
      state[currentIndex].country &&
      state[currentIndex].region
    ) {
      getCityList();
    }
  }, [currentRegionSearchStr]);

  const addClickHandler = () => {
    setAddFeild((prevState) => [...prevState, 1]);

    if (settingsUpdatedLocation === MDRIpConfigLoc || settingsUpdatedLocation === DDVIpconfigLoc) {
      setState((prevState) => [...prevState, { ...MDRIpConfigState }]);
      seterrorStateArr((prevState) => [...prevState, { ...MDRIpConfigState }]);
    } else if (settingsUpdatedLocation === MDRAccountIdConfigLoc) {
      setState((prevState) => [...prevState, { ...MDRAccountIdState }]);
      seterrorStateArr((prevState) => [...prevState, { ...MDRAccountIdState }]);
    }
  };

  const deleteClickhandler = (index) => {
    let latestClonnedState = cloneDeep(state);
    latestClonnedState.splice(index, 1);
    let clonnedErrorStateArr = cloneDeep(errorStateArr);
    clonnedErrorStateArr.splice(index, 1);
    addFeild.splice(index, 1);
    setState([...latestClonnedState]);
    setAddFeild(addFeild);
    seterrorStateArr([...clonnedErrorStateArr]);
  };

  const checkAllFieldsFilledFunction = () => {
    // {accountId: '', country: '', region: '', city: '', environment: ''}
    let makeAPIflag = true;

    let errorsArr = [...errorStateArr];
    state.forEach((stateObj, index) => {
      for (const property in errorsArr[index]) {
        if (
          !stateObj[property] &&
          property !== "subnetMask" &&
          property !== "latitude" &&
          property !== "longitude"
        ) {
          makeAPIflag = false;
          errorsArr[index][property] = "Required Field";
        } else if (property === "ip4Address") {
          if (
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
              stateObj[property]
            )
          ) {
            errorsArr[index][property] = "";
          } else {
            makeAPIflag = false;
            errorsArr[index][property] = "Invalid IP";
          }
        } else if (property === "subnetMask" && stateObj[property]) {
          if (
            typeof stateObj[property] !== "number" &&
            isNaN(Number(stateObj[property]))
          ) {
            makeAPIflag = false;
            errorsArr[index][property] = "Subnet Mask must be a number";
          } else {
            errorsArr[index][property] = "";
          }
        } else {
          errorsArr[index][property] = "";
        }
      }
      seterrorStateArr([...errorsArr]);
    });

    return makeAPIflag;
  };
  const submitHandler = () => {
    console.log("submitHandler");
    const PostFunction = async () => {
      let URL = "";
      if (settingsUpdatedLocation === MDRIpConfigLoc || settingsUpdatedLocation === DDVIpconfigLoc) {
        URL = MDR_TENENT_IP_GEO_LOCATION_URL;
      } else if (settingsUpdatedLocation === MDRAccountIdConfigLoc) {
        URL = MDR_TENENT_ACCOUNT_ID_LOCATION_URL;
      }

      const res = await getService({
        method: "POST",
        url: URL,
        headers: getAxiosHeaders(true),
        data: state,
      });
      if (res.status === 200) {
        setopenSuccess(true);
        if (settingsUpdatedLocation === MDRIpConfigLoc || settingsUpdatedLocation === DDVIpconfigLoc) {
          setState([{ ...MDRIpConfigState }]);
        } else if (settingsUpdatedLocation === MDRAccountIdConfigLoc) {
          setState([{ ...MDRAccountIdState }]);
        }
      } else {
        setopenError({
          open: true,
          message:
            res.response && res.response.data && res.response.data.errorMessage,
        });
      }
    };
    let checkAllFieldsFilled = checkAllFieldsFilledFunction();
    console.log("checkAllFieldsFilled", checkAllFieldsFilled);
    if (checkAllFieldsFilled && state.length > 0) {
      PostFunction();
    } else if (!state.length) {
      setopenError({
        open: true,
        message: "Please add input fields",
      });
    }
  };

  const cancelHandler = () => {
    const currentLoc = history.location.pathname;
    if (currentLoc === "/tenant/ipConfiguration") {
      history.push("/tenant/IPBasedGeolocation");
    } else {
      history.push("/mdr/Settings");
    }
  };
  return (
    <div>
      <div className="MDR_SettingsContent_Container">
        <Snackbar
          open={openError.open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity="error">
            {openError.message}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleClose} severity="success">
            Adding configuration details successfull
          </Alert>
        </Snackbar>

        <div className="ManagementContent_Header">
          <div className="ManagementContent_Heading">
            {/* Enter below details to onboard new users */}
          </div>
          {settingsUpdatedLocation === DDVIpconfigLoc ? (
            <AddDDVConfig
              className="createUserButton"
              onClick={addClickHandler}
            >
              Add
            </AddDDVConfig>
          ) : (
            <AddMDRConfig
              className="createUserButton"
              onClick={addClickHandler}
            >
              Add
            </AddMDRConfig>
          )}
        </div>
        <div>
          {state.length
            ? state.map((el, index1) => (
                <Grid
                  container
                  direction="row"
                  className="MDRInputField_flex"
                  key={index1}
                >
                  <Grid item xs={1}>
                    <div className="MDRinputFieldNumber_container">
                      <div className="MDRinputFieldNumber">{index1 + 1}</div>
                    </div>
                  </Grid>
                  <MDRInputCommon
                    inputFieldsNames={inputFieldsNames}
                    state={state}
                    inputHandler={inputHandler}
                    index1={index1}
                    errorStateArr={errorStateArr}
                    countryList={countryList}
                    regionsList={regionsList}
                    citiesList={citiesList}
                    setcurrentCountrySearchStr={setcurrentCountrySearchStr}
                    setcurrentRegionSearchStr={setcurrentRegionSearchStr}
                    addLatitudeAndLongitude={addLatitudeAndLongitude}
                  ></MDRInputCommon>

                  <div
                    style={{
                      marginLeft: "2px",
                      marginTop: "2.2rem",
                    }}
                  >
                    <DeleteButtonForMDR
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        deleteClickhandler(index1);
                        // history.push(`./${queryParam.id}/clonePolicy`);
                        // setEditable(true);
                      }}
                    >
                      <DeleteIcon />
                    </DeleteButtonForMDR>
                  </div>
                </Grid>
              ))
            : null}
        </div>
      </div>
      <ManagementFooter
        cancelHandler={cancelHandler}
        submitHandler={submitHandler}
        // state={state}
        classname="MDRsettingsFooter"
        loc={settingsUpdatedLocation}
      ></ManagementFooter>
    </div>
  );
};

export default MDRSettingsContent;
