import React, { useState } from "react";
import Toggler from "../../shared/Toggler";
import { useSelector } from "react-redux";

function ManageDataMap({setActiveTab }) {
  const [togglers, setTogglers] = useState([
    { name: "Data Assets", state: "active" },
    { name: "Location", state: "disable" },
  ]);

  const locationMapData = useSelector(
    (state) => state.locationReducer.locationData
  );

  const handleTabChange = (newActiveTab) => {
    setActiveTab(newActiveTab);
    setTogglers((prevTogglers) =>
      prevTogglers.map((toggler) =>
        toggler.name === newActiveTab
          ? { ...toggler, state: "active" }
          : { ...toggler, state: "disable" }
      )
    );
  };

  const renderToggler = () => {
    if (locationMapData && locationMapData.length > 0) {
      return (
        <Toggler
          togglers={togglers}
          setTogglers={setTogglers}
          handleToggle={handleTabChange}
        />
      );
    }
  };

  return (
    <div className="togglerandSearch_Conatiner">
      {renderToggler()}
    </div>
  );
}

export default ManageDataMap;
