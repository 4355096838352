import {
  CLEAR_LOCATION_DATA,
  SET_ALL_IPS,
  SET_IP_CONFIG_TOTAL_COUNT,
  SET_LOCATION_DATA,
  SET_LOCATION_LOADER,
  SET_LOCATION_SNACKBAR_MESSAGE,
  SET_LOCATION_TABLE_DATA,
  SET_SELECTED_LIST,
} from "../locationConstants";

const initialState = {
  locationData: null,
  loader: false,
  locationTableData: null,
  snackBarMessage: null,
  isLocation: false,
  ipConfig_totalCount: [],
  allIPs: [],
  currentPage: 0,
  recordsPerPage: 20,
  selectedList: [],
};

export const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCATION_DATA: {
      return {
        ...state,
        locationData: action.payload,
      };
    }
    case SET_LOCATION_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }
    case SET_LOCATION_SNACKBAR_MESSAGE: {
      return {
        ...state,
        snackBarMessage: action.payload,
      };
    }
    case SET_LOCATION_TABLE_DATA: {
      return {
        ...state,
        locationTableData: action.payload,
      };
    }
    case SET_LOCATION_SNACKBAR_MESSAGE: {
      return {
        ...state,
        snackBarMessage: action.payload,
      };
    }
    case CLEAR_LOCATION_DATA: {
      return {
        ...state,
        locationData: null,
        loader: false,
        snackBarMessage: null,
      };
    }

    case SET_ALL_IPS:
      return {
        ...state,
        allIPs: action.payload,
      };

    case SET_IP_CONFIG_TOTAL_COUNT:
      return {
        ...state,
        ipConfig_totalCount: action.payload,
      };

    case SET_SELECTED_LIST:
      return {
        ...state,
        selectedList: action.payload
      }

    default:
      return state;
  }
};
