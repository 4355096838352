import { domain_vizr } from "../../../redux/constants/constants";

export const LOCATION_DATA_API = `${domain_vizr}/tenant/locationDataMap`;
export const GET_LOCATION_DATA = "GET_LOCATION_DATA";
export const SET_LOCATION_DATA = "SET_LOCATION_DATA";
export const SET_LOCATION_LOADER = "SET_LOCATION_LOADER";
export const CLEAR_LOCATION_DATA = "CLEAR_LOCATION_DATA";
export const SET_LOCATION_SNACKBAR_MESSAGE = "SET_LOCATION_SNACKBAR_MESSAGE";
export const locationTableDataApi = (lat, long) => {
  return `${domain_vizr}/tenant/actorLocationData?latitude=${lat}&longitude=${long}`
};
export const GET_LOCATION_TABLE_DATA = "GET_LOCATION_TABLE_DATA";
export const SET_LOCATION_TABLE_DATA = "SET_LOCATION_TABLE_DATA";
export const FETCH_IPS_REQUEST = "FETCH_IPS_REQUEST";
export const DELETE_IPS_REQUEST = "DELETE_IPS_REQUEST";
export const SET_ALL_IPS = "SET_ALL_IPS";
export const SET_IP_CONFIG_TOTAL_COUNT = "SET_IP_CONFIG_TOTAL_COUNT";
export const SET_SELECTED_LIST = "SET_SELECTED_LIST";
