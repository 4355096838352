import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EnhancedTableToolbar } from "../MDRSettings/EnhanaceMDRSettingsHeader";
import EnhancedGridTable from "../../shared/Table/Table";
import { tableHeadCells_allIps } from "../MDRSettings/MDRSettingsUtilities";
import {
  DDVIpconfigLoc,
} from "../../../redux/constants/constants";
import { getAxiosHeaders, getService } from "../../../library/RestAPI";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import { DELETE_IPS_REQUEST, FETCH_IPS_REQUEST } from "./locationConstants";

function IPBasedGeolocation() {
  const dispatch = useDispatch();
  const { ipConfig_totalCount, allIPs, loader, selectedList } = useSelector(
    (state) => state.locationReducer
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(20);

  useEffect(() => {
    dispatch({
      type: FETCH_IPS_REQUEST,
      payload: { recordsPerPage, currentPage },
    });
  }, [dispatch, recordsPerPage, currentPage]);

  const ipConfigDeleteHandler = () => {
    dispatch({ type: DELETE_IPS_REQUEST, payload: { selectedList, recordsPerPage, currentPage } });
  };

  const handleChangeRowsPerPage = (value) => {
    setCurrentPage(0);
    setRecordsPerPage(value);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const renderHeaderInfo = () => {
    return (
      <div className="description">
        <div style={{ display: "grid" }}>
          <h2 style={{ margin: "0", fontSize: "32px" }}>
            IP-based Geolocation
          </h2>
        </div>
      </div>
    );
  };

  return (
    <div className="main">
      {renderHeaderInfo()}

      <div className="filter-table-container">
        <div className="table-container">
          {loader && (
            <div className="global-loader-holder">
              <img src={Page_Loader} alt="Loader" className="loader" />
            </div>
          )}

          <EnhancedTableToolbar
            totalElements={ipConfig_totalCount}
            selectedList={selectedList}
            rows={allIPs}
            loc={DDVIpconfigLoc}
            alertsTitle={"Private IP-based Geolocation"}
            helpDescription={"Geolocation information for your private IPs"}
            deleteHandler={ipConfigDeleteHandler}
          />

          <EnhancedGridTable
            cols={tableHeadCells_allIps(selectedList, allIPs, dispatch)}
            data={allIPs}
            totalCount={ipConfig_totalCount}
            currentPage={currentPage}
            recordsPerPage={recordsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onChangePage={handleChangePage}
            loc={DDVIpconfigLoc}
          />
        </div>
      </div>
    </div>
  );
}

export default IPBasedGeolocation;
