import InitialSetupIcon from "./assets/vizr_images/Group-6.svg";
import DashboardIcon from "./assets/vizr_images/Group-5.svg";
import SystemSummaryIcon from "./assets/vizr_images/Group-4.svg";
import AlertIcon from "./assets/vizr_images/Group-3.svg";
import DataMapIcon from "./assets/vizr_images/Group-1.svg";
import DataFlowIcon from "./assets/vizr_images/ic_data_flow.svg";
import FlowGroupIcon from "./assets/vizr_images/ic_flow_group.svg";
import AlertPoliciesIcon from "./assets/vizr_images/Group-2.svg";
import IntegrationsHealthIcon from "./assets/vizr_images/Group.svg";
import tenantMangementIcon from "./assets/vizr_images/tenantMangement.svg";
import userManagementIcon from "./assets/vizr_images/userManagement.svg";
import platformSettingsIcon from "./assets/vizr_images/platformSettings.svg";
import DBUsers from "./assets/vizr_images/AccessRights.svg";
import TransactionIcon from "./assets/vizr_images/ic_transaction.svg";
import ViewLogsIcon from "./assets/vizr_images/view_logs.svg";
import DataSources from "./assets/vizr_images/dataSources.svg";
import AdvanceOptions5 from "./assets/vizr_images/AdvanceOptions.svg";
import activeDashboard from "./assets/vizr_images/activeDashboard.svg";
import activeAccessRights from "./assets/vizr_images/activeAccessRights.svg";
import activeAlerts from "./assets/vizr_images/activeAlerts.svg";
import activeDataMap from "./assets/vizr_images/activeDataMap.svg";
import activeDataAccessLogs from "./assets/vizr_images/activeDataAccessLogs.svg";
import activeFlows from "./assets/vizr_images/activeFlows.svg";
import activeNodes from "./assets/vizr_images/activeNodes.svg";
import activePolicies from "./assets/vizr_images/activePolicies.svg";
import activeInitialSetup from "./assets/vizr_images/activeInitialSetup.svg";
import activeUserManagement from "./assets/vizr_images/activeUserManagement.svg";
import activeSystemSummary from "./assets/vizr_images/activeSystemSummary.svg";
import locationIcon from "./assets/vizr_images/locationIcon.svg";
import activateLocationIcon from "./assets/vizr_images/activeLocationIcon.svg";

import {
  addAgent,
  updateAlert,
  viewAlertPolicy,
  viewDataFlows,
  viewDataMap,
  viewDataFlowGroups,
  viewDataSource,
  addTenantUser,
  viewAgentStatus,
  addPlatformUser,
  addTenant,
  viewSetting,
  viewDataFlow,
  reviewDataSource,
  viewDataFlowGroup,
  VIEW_ENTERPRISE_ACTORS,
  viewDatabaseLogs,
  viewAlert,
  attachEdition,
  MDR_CONFIG
} from "./redux/constants/constants";

var tenant = window.localStorage.getItem("tenant");

export const MDRroutes = [
  {
    path: `mdr/MDRdashboards`,
    name: "Dashboard",
    condition: "",
    icon: DashboardIcon,
    activeIcon: activeDashboard,
  },
  {
    path: `mdr/userManagement`,
    name: "User Management",
    iconMargin: "-16px",
    condition: addTenantUser,
    icon: userManagementIcon,
    activeIcon: activeUserManagement,
  },
];

var routes = [
  {
    path: `/dataMap`,
    name: "Data Map",
    condition: viewDataMap,
    icon: DataMapIcon,
    activeIcon: activeDataMap,
  },
  {
    path: `/dashboard`,
    name: "Dashboard",
    condition: "",
    icon: DashboardIcon,
    activeIcon: activeDashboard,
  },
  {
    path: `/alerts`,
    name: "Alerts",
    condition: viewAlert,
    icon: AlertIcon,
    activeIcon: activeAlerts
  },
  {
    path: `/useraccessRights`,
    name: "Access Rights",
    condition: VIEW_ENTERPRISE_ACTORS
      ? VIEW_ENTERPRISE_ACTORS
        ? VIEW_ENTERPRISE_ACTORS
        : VIEW_ENTERPRISE_ACTORS
      : "",
    icon: DBUsers,
    activeIcon: activeAccessRights,
  },
  {
    path: `/alertPolicies`,
    name: "Policies",
    condition: viewAlertPolicy,
    icon: AlertPoliciesIcon,
    activeIcon: activePolicies,
  },
  {
    path: `/userManagement`,
    name: "User Management",
    condition: addTenantUser,
    icon: userManagementIcon,
    activeIcon: activeUserManagement,
  },
  // {
  //   path: `/integrations`,
  //   name: "Integrations & Health",
  //   condition: "",
  //   icon: IntegrationsHealthIcon,
  // },
  {
    path: `/systemSummary`,
    name: "System Summary",
    condition: viewDataSource,
    icon: SystemSummaryIcon,
    activeIcon: activeSystemSummary,
  },
  {
    path: `/initialSetup`,
    name: "Initial Setup",
    condition: addAgent,
    icon: InitialSetupIcon,
    activeIcon: activeInitialSetup,
  },
  {
    path: ``,
    name: "Advanced",
    condition: viewDataFlow,
    icon: AdvanceOptions5,
    innerLinks: [
      {
        path: `/reviewDataSources`,
        name: "Nodes",
        condition: viewDataSource,
        icon: DataSources,
        activeIcon: activeNodes,
      },
      {
        path: `/dataFlows`,
        name: "Flows",
        condition: viewDataFlow,
        icon: TransactionIcon,
        activeIcon: activeFlows,
      },

      // {
      //   path: `/dataFlowGroups`,
      //   name: "Data Flow Groups",
      //   condition: viewDataFlowGroup,
      //   icon: FlowGroupIcon,
      //   iconMargin: "-16px",
      // },
      {
        path: `/databaseLogs`,
        name: "Data Access Logs",
        condition: viewDatabaseLogs,
        icon: ViewLogsIcon,
        activeIcon: activeDataAccessLogs,
      },
      {
        path: `/IPBasedGeolocation`,
        name: "IP Based Geolocation",
        condition: MDR_CONFIG,
        icon: locationIcon,
        activeIcon: activateLocationIcon,
      },
    ],
  },

  {
    path: `/tenantManagement`,
    name: "Tenant Management",
    icon: tenantMangementIcon,
    condition: addTenant,
  },
  {
    path: `/userManagement`,
    name: "User Management",
    condition: addPlatformUser,
    icon: userManagementIcon,
  },
  {
    path: `/platformSettings`,
    name: "Platform Settings",
    condition: addTenant,
    icon: platformSettingsIcon,
  },
  {
    path: ``,
    name: "Settings",
    condition: [
      viewSetting,
      addPlatformUser,
      addTenant,
      viewAgentStatus,
      viewAgentStatus,
    ],
    icon: platformSettingsIcon,
    innerLinks: [
      {
        path: `/userManagement`,
        name: "User Management",
        condition: addTenantUser,
        icon: userManagementIcon,
      },
      // {
      //   path: `/platformManagement`,
      //   name: "Platform Management",
      //   icon: SystemSummaryIcon,
      //   condition: viewAgentStatus,
      // },
      {
        path: `/tenantManagement`,
        name: "Tenant Management",
        icon: tenantMangementIcon,
        condition: addTenant,
      },
      {
        path: `/userManagement`,
        name: "User Management",
        condition: addPlatformUser,
        icon: userManagementIcon,
      },
    ],
  },
];

export default routes;
