import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import "./RiskGovernance.scss";
import {
  CLEAR_FILTERS_RP,
  riskStatusLookUp,
  riskTypeLookup,
  SET_FILTER_RR,
} from "./riskPilotConstant";
import { useDispatch } from "react-redux";

const RiskFilterComp = ({ filterData, column }) => {
  const [filters, setFilters] = useState([]);
  const dispatch = useDispatch();

  const columnNameLookUp = {
    Status: "status",
    Risk: "riskType",
    Severity: "severity",
    Priority: "priority",
  };

  const reverseColumnNameLookup = Object.fromEntries(
    Object.entries(columnNameLookUp).map(([key, value]) => [value, key])
  );

  // Initialize filters from filterData
  useEffect(() => {
    const initialFilters = (filterData || []).map((filter, index) => {
      const [key, data] = Object.entries(filter)[0];
      return {
        id: Date.now() + index,
        column: reverseColumnNameLookup[key] || "",
        operator: data.operator || "equals",
        value: data.value || "",
      };
    });

    setFilters(
      initialFilters.length
        ? initialFilters
        : [{ id: Date.now(), column: reverseColumnNameLookup[column], operator: "equals", value: "" }]
    );
  }, [filterData]);

  const handleAddFilter = () => {
    const usedColumns = filters.map((filter) => filter.column);
    const availableColumns = columnsList.filter(
      (col) => !usedColumns.includes(col)
    );

    if (availableColumns.length > 0) {
      setFilters([
        ...filters,
        {
          id: Date.now(),
          column: availableColumns[0],
          operator: "equals",
          value: "",
        },
      ]);
    }
  };

  const handleRemoveFilter = (filterId) => {
    const updatedFilters = filters.filter((filter) => filter.id !== filterId);
    setFilters(updatedFilters);
  };

  const handleFilterChange = (id, field, value) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter.id === id ? { ...filter, [field]: value } : filter
      )
    );
  };

  const handleApplyFilters = () => {
    const updatedFilterData = filters.map((filter) => {
      const columnKey = columnNameLookUp[filter.column];
      return {
        [columnKey]: {
          operator: filter.operator,
          value: filter.value,
        },
      };
    });

    dispatch({
      type: SET_FILTER_RR,
      payload: updatedFilterData,
    });
  };

  const handleClearFilters = () => {
    setFilters([]);
    dispatch({ type: CLEAR_FILTERS_RP });
  };

  const columnsList = ["Risk", "Priority", "Status", "Severity"];

  const renderFieldValues = (column, value) => {
    if (column === "Risk" && riskTypeLookup[value]) {
      return riskTypeLookup[value];
    } else if (column === "Status" && riskStatusLookUp[value]) {
      return riskStatusLookUp[value];
    } else {
      return value;
    }
  };

  // Disable the Add Filter button if all columns are used
  const usedColumns = filters.map((filter) => filter.column);
  const addFilterDisabled = usedColumns.length >= columnsList.length;

  // Disable Apply Filter button if any filter value is empty
  const applyFilterDisabled = filters.some((filter) => !filter.value.trim());

  return (
    <div className="filterPanel">
      {filters.map((filter) => {
        // Exclude columns already selected in other filters
        const availableColumns = columnsList.filter(
          (col) =>
            col === filter.column ||
            !usedColumns.includes(col) ||
            filter.column === col
        );

        return (
          <div key={filter.id} className="filterRow">
            <div className="filterField">
              <FormControl fullWidth>
                <InputLabel>Column</InputLabel>
                <Select
                  value={filter.column}
                  onChange={(e) =>
                    handleFilterChange(filter.id, "column", e.target.value)
                  }
                  label="Column"
                >
                  {availableColumns.map((column) => (
                    <MenuItem key={column} value={column}>
                      {column}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="operatorField">
              <FormControl fullWidth>
                <InputLabel>Operator</InputLabel>
                <Select
                  value={filter.operator}
                  onChange={(e) =>
                    handleFilterChange(filter.id, "operator", e.target.value)
                  }
                  label="Operator"
                >
                  <MenuItem value="contains">Contains</MenuItem>
                  <MenuItem value="equals">Equals</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="valueField">
              <TextField
                label="Value"
                value={renderFieldValues(filter.column, filter.value)}
                onChange={(e) =>
                  handleFilterChange(filter.id, "value", e.target.value)
                }
              />
            </div>

            {filters.length > 1 && (
              <IconButton
                color="error"
                onClick={() => handleRemoveFilter(filter.id)}
                className="removeFilterBtn"
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        );
      })}
      <div className="filterButtonContainer">
        <div className="applyFilterButton">
          <Button
            variant="contained"
            onClick={handleApplyFilters}
            disabled={applyFilterDisabled}
            sx={{
              backgroundColor: applyFilterDisabled ? "#cccccc" : "#2999ca",
              "&:hover": {
                backgroundColor: applyFilterDisabled ? "#cccccc" : "#1a7d8c",
              },
            }}
          >
            Apply Filter
          </Button>
        </div>
        <div className="filterButtons">
          <div className="addFilterButton">
            <Button
              variant="contained"
              className={addFilterDisabled ? "notAllowed" : null}
              onClick={handleAddFilter}
              disabled={addFilterDisabled}
              sx={{
                backgroundColor: addFilterDisabled ? "#cccccc" : "#2999ca",
                "&:hover": {
                  backgroundColor: addFilterDisabled ? "#cccccc" : "#1a7d8c",
                },
              }}
            >
              Add Filter
            </Button>
          </div>

          <div className="actions">
            <Button
              variant="outlined"
              onClick={handleClearFilters}
              sx={{
                borderColor: "#2999ca",
                color: "#2999ca",
                "&:hover": { borderColor: "#1a7d8c", color: "#1a7d8c" },
              }}
            >
              Remove All
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskFilterComp;