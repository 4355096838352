import React, { useState, useEffect } from "react";
import EnhancedGridTable from "../../shared/Table/Table";
import { EnhancedTableToolbar } from "./EnhanaceMDRSettingsHeader";
import { useLocation, useHistory } from "react-router-dom";
import {
  tableHeadCells_allIps,
  tableHeadCells_accountId,
} from "./MDRSettingsUtilities";
import "./MDRSettings.scss";
import "../../../assets/scss/Usermanagement.scss";
import { getService, getAxiosHeaders } from "../../../library/RestAPI";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import {
  MDR_TENENT_IP_GEO_LOCATION_URL,
  MDR_TENENT_ACCOUNT_ID_LOCATION_URL,
  MDR_TENENT_PATCHMONITORING_CONFIGURATION_URL,
  MDRIpConfigLoc,
  MDRAccountIdConfigLoc,
  MDRPatchMonitoringConfiguratiomFileds,
  MDRPatchMonitoringConfiguration,
  MDRPatchMonitoringConfigurationLoc,
} from "../../../redux/constants/constants";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid } from "@material-ui/core";
import ManagementFooter from "../../views/BackOfficeGeneralContainer/ManagementFooter";
import Checkbox from "@material-ui/core/Checkbox";
import Snackbar from "@material-ui/core/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_IPS_REQUEST, SET_LOCATION_LOADER, SET_SELECTED_LIST } from "../manageDataMap/locationConstants";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const MDRSettings = () => {
  const dispatch = useDispatch();
  let currentLoc = sessionStorage.getItem("MDR_SETTINGS_UPDATE_LOCATION");
  const [IpBasedGeoLocation, setIpBasedGeoLocation] = useState(
    currentLoc === MDRIpConfigLoc ? true : !currentLoc ? true : false
  );
  const [AccountBasedGeoLocation, setAccountBasedGeoLocation] = useState(
    currentLoc === MDRAccountIdConfigLoc ? true : false
  );

  const { ipConfig_totalCount, allIPs, loader, selectedList } = useSelector(
      (state) => state.locationReducer
    );

  const location = useLocation();
  const history = useHistory();

  const [patchMonitoringConfiguration, setPatchMonitoringConfiguration] =
    useState(currentLoc === MDRPatchMonitoringConfiguration ? true : false);
  const [allAccounts, setAllAccounts] = useState([]);
  const [accountId_totalCount, setaccountId_totalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [inputFieldsNames, setInputFieldNames] = useState({});
  const [openSuccess, setopenSuccess] = useState(false);
  const [openError, setopenError] = useState({ open: false, message: "" });
  const [patchMonitoringData, setpatchMonitoringData] = useState({
    clientId: "",
    tenantId: "",
    clientSecret: "",
    workspaceId: "",
  });
  const [checkedValue, setCheckedValue] = useState(false);

  const [state, setState] = useState({
    clientSecret: "",
    clientId: "",
    tenantId: "",
    workspaceId: "",
  });
  const [id, setId] = useState("");

  const activeToggle = (payload) => {
    if (payload === "Ip") {
      //! To Reset the Patch monitoring related state
      setCheckedValue(false);
      setError({
        clientSecret: "",
        clientId: "",
        tenantId: "",
        workspaceId: "",
      });
      setIpBasedGeoLocation(true);
      dispatch({ type: SET_SELECTED_LIST, payload: []})
      setAccountBasedGeoLocation(false);
      setPatchMonitoringConfiguration(false);
      setRecordsPerPage(20);
      setCurrentPage(0);
      sessionStorage.setItem("MDR_SETTINGS_UPDATE_LOCATION", MDRIpConfigLoc);
      setPatchMonitoringConfiguration(false);
    } else if (payload === "accountId") {
      //! To Reset the Patch monitoring related state
      setCheckedValue(false);
      setError({
        clientSecret: "",
        clientId: "",
        tenantId: "",
        workspaceId: "",
      });
      dispatch({ type: SET_SELECTED_LIST, payload: []});
      setIpBasedGeoLocation(false);
      setAccountBasedGeoLocation(true);
      setPatchMonitoringConfiguration(false);
      setRecordsPerPage(20);
      setCurrentPage(0);
      sessionStorage.setItem(
        "MDR_SETTINGS_UPDATE_LOCATION",
        MDRAccountIdConfigLoc
      );
      setPatchMonitoringConfiguration(false);
    } else if (payload === "patchMonitoringConfig") {
      sessionStorage.setItem(
        "MDR_SETTINGS_UPDATE_LOCATION",
        MDRPatchMonitoringConfiguration
      );
      dispatch({ type: SET_SELECTED_LIST, payload: []});
      setPatchMonitoringConfiguration(true);
      setIpBasedGeoLocation(false);
      setAccountBasedGeoLocation(false);
    }
  };
  const getDataService = (url, recordsPerPage, currentPage) => {
    if (recordsPerPage || currentPage) {
      return getService({
        method: "GET",
        // url: `${url}`,
        url: `${url}?size=${recordsPerPage}&page=${currentPage}`,
        headers: getAxiosHeaders(true),
      });
    } else {
      return getService({
        method: "GET",
        url: `${url}`,
        headers: getAxiosHeaders(true),
      });
    }
  };
  const handleChangeRowsPerPage = (value) => {
    setCurrentPage(0);
    setRecordsPerPage(value);
  };
  const handleChangePage = (page) => {
    setCurrentPage(page);
    // setRecordsPerPage(20);

    // setLoader(true);
  };
  const [error, setError] = useState({
    clientSecret: "",
    clientId: "",
    tenantId: "",
    workspaceId: "",
  });
  const checkAllFieldsFilledFunction = () => {
    let errorObj = { ...error };
    let makeAPIflag = true;
    for (const prop in patchMonitoringData) {
      if (!patchMonitoringData[prop]) {
        makeAPIflag = false;
        errorObj[prop] = "Field Required";
      } else {
        errorObj[prop] = "";
      }
    }

    setError(errorObj);
    return makeAPIflag;
  };
  const submitHandler = () => {
    const PostFunction = async () => {
      let URL = MDR_TENENT_PATCHMONITORING_CONFIGURATION_URL;

      const res = await getService({
        method: id ? "PUT" : "POST",
        url: URL,
        headers: getAxiosHeaders(true),
        data: patchMonitoringData,
      });
      if (res.status === 200) {
        console.log("Success");
        setopenSuccess(true);
      } else {
        setopenError({
          open: true,
          message:
            res.response && res.response.data && res.response.data.errorMessage,
        });
      }
    };
    let checkAllFieldsFilled = checkAllFieldsFilledFunction();
    if (checkAllFieldsFilled) {
      PostFunction();
    } else {
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setopenSuccess(false);
    setopenError({ open: false, message: "" });
  };
  const inputHandler = (event, index, inputFieldsNames) => {
    let item = { ...patchMonitoringData };
    if (inputFieldsNames === "grantType") {
      item.grantType = event.target.value;
    } else if (inputFieldsNames === "Client secret value") {
      item.clientSecret = event.target.value;
    } else if (inputFieldsNames === "Application (client) ID") {
      item.clientId = event.target.value;
    } else if (inputFieldsNames === "Directory (tenant) ID") {
      item.tenantId = event.target.value;
    } else if (inputFieldsNames === "Analytics Workspace ID") {
      item.workspaceId = event.target.value;
    }
    //  else if (inputFieldsNames === "accessToken") {
    //   state.accessToken = event.target.value;
    // }
    console.log("item", item);
    setState(item);
    setpatchMonitoringData(item);
  };
  useEffect(() => {
    if (IpBasedGeoLocation) {
      dispatch({
        type: FETCH_IPS_REQUEST,
        payload: { recordsPerPage, currentPage },
      });
    } else if (AccountBasedGeoLocation) {
      dispatch({type: SET_LOCATION_LOADER, payload: true});
      getDataService(
        MDR_TENENT_ACCOUNT_ID_LOCATION_URL,
        recordsPerPage,
        currentPage
      ).then((res) => {
        if (res.status === 200) {
          dispatch({type: SET_LOCATION_LOADER, payload: false});
          setAllAccounts(res.data ? res.data.content : []);
          setaccountId_totalCount(res.data ? res.data.totalElements : 0);
        }
      });
    } else if (patchMonitoringConfiguration) {
      dispatch({type: SET_LOCATION_LOADER, payload: false});
      setInputFieldNames({ ...MDRPatchMonitoringConfiguratiomFileds });
      getDataService(MDR_TENENT_PATCHMONITORING_CONFIGURATION_URL).then(
        (res) => {
          if (res.status === 200) {
            let dataObj =
              res.data &&
              res.data.content &&
              res.data.content.length &&
              res.data.content[0];
            setId(dataObj.id ? dataObj.id : "");
            if (dataObj.id) {
              setCheckedValue(false);
            } else {
              setCheckedValue(true);
            }
            setpatchMonitoringData({
              clientId: dataObj.clientId,
              tenantId: dataObj.tenantId,
              clientSecret: dataObj.clientSecret,
              workspaceId: dataObj.workspaceId,
            });
          }
        }
      );
    }
  }, [
    IpBasedGeoLocation,
    AccountBasedGeoLocation,
    patchMonitoringConfiguration,
    recordsPerPage,
    currentPage,
  ]);

  useEffect(() => {
    return () => {
      if (
        !(history.location.pathname === "/mdr/Settings") &&
        !(history.location.pathname === "/mdr/Settings/ipConfiguration") &&
        !(history.location.pathname === "/mdr/Settings/accountIdConfiguration")
      ) {
        sessionStorage.clear();
      }
    };
  }, [history]);

  const deleteFunction = async (URL) => {
    let delIdStr = selectedList.join(",");
    const res = await getService({
      method: "DELETE",
      url: `${URL}/${delIdStr}`,
      headers: getAxiosHeaders(true),
    });
    if (res.status === 200 && currentPage === 0) {
      dispatch({ type: SET_SELECTED_LIST, payload: []});
      if (IpBasedGeoLocation) {
        dispatch({ type: FETCH_IPS_REQUEST, payload: { recordsPerPage, currentPage } });
      } else if (AccountBasedGeoLocation) {
        dispatch({type: SET_LOCATION_LOADER, payload: true});
        getDataService(
          MDR_TENENT_ACCOUNT_ID_LOCATION_URL,
          recordsPerPage,
          currentPage
        ).then((res) => {
          if (res.status === 200) {
            dispatch({type: SET_LOCATION_LOADER, payload: false});
            setAllAccounts(res.data ? res.data.content : []);
            setaccountId_totalCount(res.data ? res.data.totalElements : 0);
          }
        });
      }
    } else if (res.status === 200) {
      setCurrentPage(0);
      dispatch({ type: SET_SELECTED_LIST, payload: []});
    } else {
      dispatch({ type: SET_SELECTED_LIST, payload: []});
      console.log(res);
      let errorMessage =
        res.response && res.response.data && res.response.data.errorMessage;
      alert(errorMessage);
    }
  };
  const ipConfigDeleteHandler = () => {
    deleteFunction(MDR_TENENT_IP_GEO_LOCATION_URL);
  };
  const accountIdConfigDeleteHandler = () => {
    deleteFunction(MDR_TENENT_ACCOUNT_ID_LOCATION_URL);
  };

  console.log(location, "testing");
  return (
    <div className="MDRSettings">
      <div className="description">
        <div style={{ display: "grid" }}>
          <div style={{ paddingTop: "20px", fontSize: "38px" }}>Settings</div>
          <p
            style={{ margin: "0", padding: "14px 0px", paddingBottom: "25px" }}
          >
            {/* Manage Data Vision Users and roles */}
          </p>
        </div>
      </div>
      <div className="MDRSettings_TabsParent">
        <div className="MDRSettings_Tabs">
          <div
            className={IpBasedGeoLocation ? "activeTab" : "InactiveTab"}
            onClick={() => activeToggle("Ip")}
          >
            Private IP-based Geolocation
          </div>
          <div
            className={AccountBasedGeoLocation ? "activeTab" : "InactiveTab"}
            onClick={() => activeToggle("accountId")}
          >
            Account ID-based Geolocation
          </div>
          <div
            className={
              patchMonitoringConfiguration ? "activeTab" : "InactiveTab"
            }
            onClick={() => activeToggle("patchMonitoringConfig")}
          >
            Patch Monitoring Configuration
          </div>
        </div>
      </div>

      <div>
        {IpBasedGeoLocation && (
          <div className="filter-table-container">
            <div className="table-container">
              {loader && (
                <div className="global-loader-holder">
                  <img src={Page_Loader} alt="_Loader" className="loader" />
                </div>
              )}
              <EnhancedTableToolbar
                totalElements={ipConfig_totalCount}
                selectedList={selectedList}
                rows={allIPs}
                loc={MDRIpConfigLoc}
                alertsTitle={"Private IP-based Geolocation"}
                helpDescription={"Geolocation information for your private IPs"}
                deleteHandler={ipConfigDeleteHandler}
                // clearFilterData={clearFilterData}
              />
              <EnhancedGridTable
                cols={tableHeadCells_allIps(
                  selectedList,
                  allIPs,
                  dispatch
                )}
                data={allIPs}
                totalCount={ipConfig_totalCount}
                currentPage={currentPage}
                recordsPerPage={recordsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onChangePage={handleChangePage}
                loc={MDRIpConfigLoc}
              />
            </div>
          </div>
        )}
        {AccountBasedGeoLocation && (
          <div className="filter-table-container">
            <div className="table-container">
              {loader && (
                <div className="global-loader-holder">
                  <img src={Page_Loader} alt="_Loader" className="loader" />
                </div>
              )}
              <EnhancedTableToolbar
                totalElements={accountId_totalCount}
                rows={allAccounts}
                loc={MDRAccountIdConfigLoc}
                selectedList={selectedList}
                alertsTitle={"Account ID-based Geolocation"}
                helpDescription={
                  "Geolocation information and friendly environment names for your Azure accounts"
                }
                deleteHandler={accountIdConfigDeleteHandler}
                // clearFilterData={clearFilterData}
              />
              <EnhancedGridTable
                cols={tableHeadCells_accountId(
                  selectedList,
                  allAccounts,
                  setAllAccounts,
                  dispatch
                )}
                data={allAccounts}
                totalCount={accountId_totalCount}
                currentPage={currentPage}
                recordsPerPage={recordsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onChangePage={handleChangePage}
                loc={MDRAccountIdConfigLoc}
              />
            </div>
          </div>
        )}
        {patchMonitoringConfiguration && (
          <div>
            <div
              className="MDR_SettingsContent_Container"
              style={{ minHeight: "38rem" }}
            >
              {loader && (
                <div className="global-loader-holder">
                  <img src={Page_Loader} alt="_Loader" className="loader" />
                </div>
              )}
              <Snackbar
                open={openError.open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <Alert onClose={handleClose} severity="error">
                  {openError.message}
                </Alert>
              </Snackbar>
              <Snackbar
                open={openSuccess}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <Alert onClose={handleClose} severity="success">
                  Patch Monitoring Configuration is Successfull !
                </Alert>
              </Snackbar>
              <div className="ManagementContent_Header">
                <div className="ManagementContent_Heading">
                  Enter below details for Patch Monitoring Configuration
                </div>
              </div>

              <div disbaled={true}>
                <Grid
                  container
                  direction="column"
                  className="MDRInputField_flex"
                  style={{ minHeight: "5rem", marginLeft: "9%" }}

                  // key={index1}
                >
                  {Object.keys(inputFieldsNames).length &&
                    Object.keys(inputFieldsNames).map(
                      (inputFieldsName, index) => {
                        return (
                          <Grid item xs={4}>
                            <>
                              <h2 className="onboarding_text">
                                {inputFieldsName}
                                <span
                                  style={{ color: "red", marginLeft: "0.2rem" }}
                                >
                                  *
                                </span>
                              </h2>
                              <input
                                disabled={!checkedValue}
                                style={{ marginBottom: "1rem" }}
                                // value={state[arrItems[index]]}
                                value={
                                  patchMonitoringData[
                                    inputFieldsNames[inputFieldsName]
                                  ]
                                    ? patchMonitoringData[
                                        inputFieldsNames[inputFieldsName]
                                      ]
                                    : ""
                                }
                                type={
                                  inputFieldsName === "Client secret value"
                                    ? "password"
                                    : "text"
                                }
                                className="MDRInputTextFeildAddConfig"
                                onChange={(event) => {
                                  inputHandler(event, index, inputFieldsName);
                                }}
                              ></input>
                              {error[inputFieldsNames[inputFieldsName]] ? (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marignTop: "0.5rem",
                                    fontFamily: "Nunito-Regular",
                                  }}
                                >
                                  {error[inputFieldsNames[inputFieldsName]]}
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          </Grid>
                        );
                      }
                    )}
                </Grid>
                {id && (
                  <div style={{ marginLeft: "6rem" }} title="Click here to enable">
                    <Checkbox
                      id="mdrSettingsEnable"
                      onChange={() => {
                        setCheckedValue(!checkedValue);
                      }}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    <label class="cursorPointer" for="mdrSettingsEnable">Enable</label>
                  </div>
                )}
              </div>
            </div>
            <ManagementFooter
              // cancelHandler={cancelHandler}
              submitHandler={submitHandler}
              classname="MDRsettingsFooter"
              loc={MDRPatchMonitoringConfigurationLoc}
              checkedValue={checkedValue}
            ></ManagementFooter>
          </div>
        )}
      </div>
    </div>
  );
};

export default MDRSettings;
