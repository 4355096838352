import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./manageDataMap.scss";
import L from "leaflet";
import { Typography } from "@material-ui/core";
import { icons } from "../../common/utilities";
import AlertBadge from "../../shared/Badge/AlertBadge";
import inspectIcon from "../../../assets/icons/inspectIcon.svg";
import Page_Loader from "../../../assets/vizr_images/page_loader.gif";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAR_LOCATION_DATA,
  GET_LOCATION_DATA,
  SET_LOCATION_SNACKBAR_MESSAGE,
} from "./locationConstants";
import {
  CLEAR_DATA_MAP,
  CLEAR_DATAMAP_MULTI_TOOLTIP,
} from "../../../redux/constants/constants";
import {
  ContentRow,
  LocationTitle,
  renderLocationSnackBar,
  renderUsersWithAlerts,
} from "./locationCommonComponents";
import { isEmpty } from "lodash";

// Custom Zoom component to handle map zoom on marker click
const ZoomMap = ({ position }) => {
  const map = useMap();

  useEffect(() => {
    if (position) {
      map.flyTo(position, 8, { animate: true, duration: 1 }); // Fly to the clicked marker position

      // Adjust center to consider popup height
      setTimeout(() => {
        const offsetY = -100; // Adjust based on popup size
        const mapSize = map.getSize();
        const offsetPoint = L.point(0, offsetY);
        const newCenter = map.containerPointToLatLng(
          mapSize.divideBy(2).add(offsetPoint)
        );
        map.panTo(newCenter);
      }, 1000); // Allow flyTo animation to complete
    }
  }, [position, map]);

  return null;
};

export default function LocationMap({
  bottomTrayEnableEve,
  locationDataInspect,
}) {
  const mapCenter = [28.6139, 77.209];
  const dispatch = useDispatch();
  const [zoomPosition, setZoomPosition] = useState(null); // State to store the zoom position
  const [zoomKey, setZoomKey] = useState(0);
  const [openError, setOpenError] = useState(false);

  const locationData = useSelector(
    (state) => state.locationReducer.locationData
  );

  const snackBarMessage = useSelector(
    (state) => state.locationReducer.snackBarMessage
  );

  const loader = useSelector((state) => state.locationReducer.loader);

  useEffect(() => {
    dispatch({ type: CLEAR_DATAMAP_MULTI_TOOLTIP });
    dispatch({ type: CLEAR_DATA_MAP });
    dispatch({ type: GET_LOCATION_DATA });
  }, []);

  const popupRefs = useRef([]);

  // const renderMarkerIcon = (location) => {
  //   const totalActivity = location.totalActivity || 0;
  
  //   // Determine point size based on the number of activities
  //   let pointSize;
  //   if (totalActivity < 1000) {
  //     pointSize = 12; // Slightly larger small point size
  //   } else if (totalActivity >= 1000 && totalActivity <= 1000000) {
  //     // Gradually increase size between 12px and 30px
  //     pointSize = 12 + 18 * Math.log10(totalActivity / 1000 + 1);
  //   } else {
  //     pointSize = 35; // Larger consistent point size for over 1,000,000 activities
  //   }

  
  //   // Ensure the size stays within bounds
  //   pointSize = Math.min(Math.max(pointSize, 10), 30);
  
  //   // Determine color based on alerts
  //   const hasAlerts = Object.values(location.alerts).some((value) => value > 0);
  //   const circleColor = hasAlerts ? "#ff2251" : "#2ce68f";
  
  //   // Create the DivIcon with dynamic size
  //   return new L.DivIcon({
  //     className: "custom-icon",
  //     html: `<div style="background-color: ${circleColor}; border-radius: 50%; width: ${pointSize}px; height: ${pointSize}px; border: 2px solid white;"></div>`,
  //     iconSize: [pointSize, pointSize],
  //   });
  // };
  

  const redCircle = new L.DivIcon({
    className: "custom-icon",
    html: `<div style="background-color: #ff2251; border-radius: 50%; width: 20px; height: 20px; border: 2px solid white;"></div>`,
    iconSize: [20, 20],
  });

  const greenCircle = new L.DivIcon({
    className: "custom-icon",
    html: `<div style="background-color: #2ce68f; border-radius: 50%; width: 20px; height: 20px; border: 2px solid white;"></div>`,
    iconSize: [20, 20],
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    dispatch({
      type: SET_LOCATION_SNACKBAR_MESSAGE,
      payload: "",
    });
  };

  useEffect(() => {
    if (!loader && !isEmpty(snackBarMessage)) {
      setOpenError(true);
    }
  }, [snackBarMessage]);

  const handleInspectClick = (index, location) => {
    bottomTrayEnableEve(true);
    locationDataInspect(location);

    // Close the popup
    if (popupRefs.current[index]) {
      popupRefs.current[index].closePopup();
    }
  };

  const handleMarkerClick = (position) => {
    setZoomPosition(position); // Set the position for zooming
    setZoomKey((prevKey) => prevKey + 1); // Change the key to trigger a re-render
  };

  const renderMarkerIcon = (data) => {
    // Check if any of the values in the object are greater than zero
    const hasAlerts = Object.values(data.alerts).some((value) => value > 0);

    if (hasAlerts) {
      return redCircle; // Return red circle if any value is greater than zero
    } else {
      return greenCircle; // Return green circle if all values are zero
    }
  };

  const renderLocationMap = () => {
    return (
      <div className="no-app-nodes">
        <div className="mapParentContainer">
          <MapContainer
            center={mapCenter}
            zoom={1}
            className="mapContainer"
            zoomControl={false}
            maxBounds={[
              [-85, -170], // South-West corner
              [85, 170], // North-East corner
            ]}
            maxBoundsViscosity={0.8}
            minZoom={1.9}
            worldCopyJump={false}
            maxZoom={8}
          >
            {/* satilite view = https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}  */}
            <TileLayer
              noWrap={true} // Prevent repeating map horizontally
              url="https://cartodb-basemaps-a.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png"
            />

            {locationData?.map((location, index) => {
              if (
                !location.position?.length ||
                location.position.length !== 2 ||
                location.position.some(isNaN)
              ) {
                return null; // Skip rendering this marker
              }
              return (
                <Marker
                  key={index}
                  position={location.position}
                  icon={renderMarkerIcon(location)}
                  title={`${location.title}`}
                  eventHandlers={{
                    click: () => handleMarkerClick(location.position), // Trigger zoom on marker click
                  }}
                  ref={(ref) => (popupRefs.current[index] = ref)}
                >
                  <div className="popUpContainer">
                    <Popup autoPan={true} autoPanPadding={[200, 200]}>
                      <Typography>
                        <div className="oneDrive-tooltip-close">
                          <div className="tt-header">
                            <div className="inspect-header-container">
                              <LocationTitle
                                title={location.title}
                                count={location.totalActivity}
                              />
                            </div>
                          </div>
                          <div className="tt-body">
                            <div className="border-top additional-content">
                              <ContentRow
                                title="Data Sources"
                                icon={location.dataSources}
                              />
                              <ContentRow
                                title="Most Activity"
                                icon={icons[location.mostAccessed.type]}
                                sourceName={location.mostAccessed.name}
                                count={location?.mostAccessed?.accessCount}
                              />
                              <ContentRow
                                title="Least Activity"
                                icon={icons[location.leastAccessed.type]}
                                sourceName={location.leastAccessed.name}
                                count={location?.leastAccessed?.accessCount}
                              />
                            </div>
                            <div className="border-top additional-content">
                              <AlertBadge
                                title={"Alerts:"}
                                parentprops={location.alerts}
                                location={true}
                              />
                            </div>
                            <div className="border-top additional-content">
                              <div className="contentHeaderContainer users">
                                <div className="contentHeaders">
                                  Users With Alerts:
                                </div>
                                <div className="locationMapIconData">
                                  {renderUsersWithAlerts(
                                    location.usersWithAlerts
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="tt-footter">
                            <div className="border-top additional-content inspectFotter">
                              <img
                                className="inspectIcon"
                                src={inspectIcon}
                                alt="Inspect Icon"
                              />
                              <div
                                className="locationInspect"
                                onClick={() =>
                                  handleInspectClick(index, location)
                                }
                              >
                                Inspect Content
                              </div>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </Popup>
                  </div>
                </Marker>
              );
            })}

            {/* ZoomMap component triggered by state, with dynamic key to force re-render */}
            {zoomPosition && <ZoomMap position={zoomPosition} key={zoomKey} />}
          </MapContainer>
        </div>
      </div>
    );
  };

  return (
    <>
      {loader && (
        <img src={Page_Loader} alt="loading" className="locationLoader"></img>
      )}
      {renderLocationMap()}
      {renderLocationSnackBar(openError, handleSnackbarClose, snackBarMessage)}
    </>
  );
}
