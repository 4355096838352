import React, { useEffect, useState } from "react";
import {
  DataGrid,
  getGridStringOperators,
  GridFilterPanel,
} from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import EditRRIcon from "../../../assets/icons/CreateRR.svg";
import {
  CLEAR_FILTERS_RP,
  GET_RISK_PILOT_ROW_BY_ID,
  SELECTED_ROW_ID,
  riskTypeIcons,
} from "./riskPilotConstant";
import RiskFilterComp from "./RiskFilterComp";
import "./RiskGovernance.scss";
import FilterIcon from '@material-ui/icons/Filter';

const RiskMitigationTable = ({
  tableData,
  currentPage,
  recordsPerPage,
  setCurrentPage,
  isEditMode,
}) => {
  const dispatch = useDispatch();

  const allRiskTypes = useSelector((state) => state.riskPilotReducer.riskTypes);
  const riskOwners = useSelector((state) => state.riskPilotReducer.riskOwners);
  const filterData = useSelector((state) => state.riskPilotReducer.filters);
  const totalRiskRegisterCount = useSelector(
    (state) => state.riskPilotReducer.totalRowCount
  );
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const customOperators = getGridStringOperators().filter((operator) =>
    ["contains", "equals"].includes(operator.value)
  );
  const [selectedColumn, setSelectedColumn] = useState(""); 

  useEffect(() => {
    setCurrentPage(paginationModel.page);
  }, [paginationModel]);

  useEffect(() => {
    return () => {
      setCurrentPage(0);
    }
  },[])

  const renderNull = () => "-";

  const renderRiskRegisterName = (params) => {
    const riskTypeValue = allRiskTypes.find(
      (risk) => risk.code === params.value
    );
    if (!riskTypeValue) return renderNull();

    return (
      <div className="riskTypeIconsContainer">
        <img
          src={riskTypeIcons[riskTypeValue.code]}
          alt={riskTypeValue.value}
        />
        <div title={riskTypeValue.value}>{riskTypeValue.value}</div>
      </div>
    );
  };

  const renderRiskSeverity = (params) => {
    if (!params.value) return renderNull();
    return (
      <div className={`riskSeverityContainer ${params.value} mitigation`}>
        <div className={`riskSeverityValue ${params.value}`}>
          {params.value}
        </div>
      </div>
    );
  };

  const renderThreat = (params) => {
    return !params.value ? (
      renderNull()
    ) : (
      <div title={params.value} className="textContainer">
        {params.value}
      </div>
    );
  };

  const renderVulnerability = (params) => {
    return !params.value ? (
      renderNull()
    ) : (
      <div title={params.value} className="textContainer">
        {params.value}
      </div>
    );
  };

  const renderMitigation = (params) => {
    return !params.value ? (
      renderNull()
    ) : (
      <div title={params.value} className="textContainer">
        {params.value}
      </div>
    );
  };

  const renderMitigationPriority = (params) => {
    if (!params.value) return renderNull();
    return (
      <div
        className={`inspect-file-severity ${params.value.toUpperCase()}`}
        style={{ borderRadius: "5px" }}
      >
        {params.value}
      </div>
    );
  };

  const renderMitigationStatus = (params) => {
    const statusIcons = {
      TODO: "/riskPilotIcons/ToDoWhite.svg",
      OPEN: "/riskPilotIcons/openWhite.svg",
      IN_PROGRESS: "/riskPilotIcons/InProgressWhite.svg",
      CLOSED: "/riskPilotIcons/closedWhite.svg",
    };
    const statusLabels = {
      TODO: "To Do",
      OPEN: "Open",
      IN_PROGRESS: "In Progress",
      CLOSED: "Closed",
    };

    const icon = statusIcons[params.value];
    const label = statusLabels[params.value];

    if (!icon || !label) return renderNull();

    return (
      <div className="mitigationCountChildContainer">
        <div className={`mitigationStatusCount ${params.value}`}>
          <div className="statusCount">
            {<img src={icon} alt={label} />}
            {label}
          </div>
        </div>
      </div>
    );
  };

  const renderMitigationOwner = (params) => {
    const ownerValue = riskOwners.find((owner) => owner.code === params.value);
    return ownerValue ? ownerValue.value : renderNull();
  };

  const renderRegisterAction = (params) => {
    const { riskId, mitigationId, vulnerabilityId, threatId } = params.row.action;
    return (
      <div className="riskTableAction">
        <img
          src={EditRRIcon}
          alt="Edit Icon"
          onClick={() => {
            dispatch({ type: CLEAR_FILTERS_RP });
            handleUpdateRow(riskId, mitigationId, vulnerabilityId, threatId);
          }}
        />
      </div>
    );
  };

  const handleUpdateRow = (riskId, mitigationId, vulnerabilityId, threatId) => {
    isEditMode(true);
    dispatch({ type: GET_RISK_PILOT_ROW_BY_ID, payload: riskId });
    dispatch({
      type: SELECTED_ROW_ID,
      payload: { mitigationId, vulnerabilityId, threatId },
    });
  };

  const renderHeaderWithFilterIcon = (field, header) => {
    // Check if the field exists in filterData
    const isFiltered = filterData?.some((item) => item.hasOwnProperty(field));
  
    return isFiltered ? (
      <div className="titleFilter" title="Filter Applied">
        {header} <img src={"/dashboardIcons/FilterIcon.svg"} />
      </div>
    ) : (
      header
    );
  };
  

  const columns = [
    { field: "riskId", headerName: "Risk ID", width: 100, filterable: false },
    {
      field: "riskType",
      headerName: renderHeaderWithFilterIcon("riskType", "Risk"),
      width: 200,
      renderCell: renderRiskRegisterName,
      filterOperators: customOperators,
    },
    {
      field: "severity",
      headerName: renderHeaderWithFilterIcon("severity", "Severity"),
      width: 140,
      renderCell: renderRiskSeverity,
      filterOperators: customOperators,
    },
    {
      field: "threat",
      headerName: "Threat",
      width: 180,
      renderCell: renderThreat,
      filterable: false,
    },
    {
      field: "vulnerability",
      headerName: "Vulnerability",
      width: 240,
      renderCell: renderVulnerability,
      filterable: false,
    },
    {
      field: "mitigation",
      headerName: "Mitigation",
      width: 240,
      renderCell: renderMitigation,
      filterable: false,
    },
    {
      field: "priority",
      headerName: renderHeaderWithFilterIcon("priority", "Priority"),
      width: 130,
      renderCell: renderMitigationPriority,
      filterOperators: customOperators,
    },
    {
      field: "status",
      headerName: renderHeaderWithFilterIcon("status", "Status"),
      width: 160,
      renderCell: renderMitigationStatus,
      filterOperators: customOperators,
    },
    {
      field: "owner",
      headerName: "Owner",
      width: 180,
      renderCell: renderMitigationOwner,
      filterable: false,
    },
    {
      field: "action",
      headerName: "Action",
      width: 70,
      renderCell: renderRegisterAction,
      filterable: false,
    },
  ];
  

  const rows = tableData.map((rowData, index) => ({
    id: index,
    riskId: rowData.riskId,
    riskType: rowData.riskType,
    severity: rowData.severity,
    threat: rowData.threat,
    vulnerability: rowData.vulnerability,
    mitigation: rowData.mitigation,
    priority: rowData.priority,
    status: rowData.status,
    owner: rowData.owner,
    action: rowData,
  }));

  const handlePaginationChange = (params) => {
    const newPagination = {
      pageSize: params.pageSize,
      page: params.page,
    };
    if (paginationModel.pageSize !== newPagination.pageSize) {
      newPagination.page = 0;
    }
    setPaginationModel(newPagination);
  };


  return (
    <div className= {rows.length === 0 ? "mitigationTableHeader noData" : "mitigationTableHeader"}>
      <DataGrid
        rows={rows}
        columns={columns}
        paginationMode="server"
        page={currentPage}
        pageSize={recordsPerPage}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationChange}
        pageSizeOptions={[]}
        rowHeight={70}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#2999ca",
            color: "white",
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: "white",
          },
        }}
        rowCount={totalRiskRegisterCount}
        filterMode="server"
        disableRowSelectionOnClick
        components={{
          FilterPanel: (props) => (
            <div className="mitigationFilterPanel">
              <RiskFilterComp
                filterData={filterData}
                column={selectedColumn}
              />
            </div>
          ),
        }}
        onFilterModelChange={(model) => {
          const currentColumn = model.items[0]?.field; // Get the field of the selected filter
          if (currentColumn) setSelectedColumn(currentColumn); // Set the column name when a filter is opened
        }}
      />
    </div>
  );
};

export default RiskMitigationTable;
