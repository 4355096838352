import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataMapInspect from "../shared/DataMapInspect";
import FilterAppByName from "../shared/FilterAppByName";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { Grid } from "@material-ui/core";
import { fade, makeStyles } from "@material-ui/core/styles";
import { MenuItem, InputLabel, FormControl, Select } from "@material-ui/core";
import { cubeQueryGen } from "../common/utilities";
import { useLocation, useHistory } from "react-router-dom";
import TableListIcon from "../../assets/icons/ic_tables.svg";
import { getCubejsApi } from "../../../src/components/common/Dashboard/cubejs-util";
import {
  SET_DATAMAP_LOADER,
  SET_GRAPH_NODES_LIST,
  SET_DATAMAP_USER_DETAILS,
  SET_ALL_NODE_DETAILS,
  GET_NODES_LIST,
  FILTER_ID,
  CLEAR_DATA_MAP,
  DATA_MAP_URL,
  HEATMAP_SELECTED_NODE,
  CLEAR_DATAMAP_MULTI_TOOLTIP,
  viewAlert,
  viewAlertPolicy,
  viewDataFlow,
  viewDataFlowGroup,
  VIEW_ENTERPRISE_ACTORS
} from "../../redux/constants/constants";
import CanAllow from "../shared/CanAllow";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import EditCriteria from "./EditCriteriaDialogue";
import { ColorButtonForClearFilter } from "../../components/common/buttons";
import ShowDataMap from "./showDataMap";
import ManageDataMap from "./manageDataMap/ManageDataMap";
import "./manageDataMap/manageDataMap.scss";
import LocationMap from "./manageDataMap/locationMap";
import InspectManager from "./InspectManager";
import { GET_LOCATION_DATA } from "./manageDataMap/locationConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    boxShadow: "0 5px 8px -3px rgba(1, 18, 121, 0.5)",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "default",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function handleClick(event) {
  event.preventDefault();
}

export default function DataMap() {
  const cubejsApi = getCubejsApi();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const selectedDateTimeRange = useSelector(
    (state) => state.selectedDateTimeRange.dateTimeRange
  );
  const permissions = useSelector((state) => state.applicationData.permissions);
  const [selectedAlertIds, setSelectedAlertIds] = useState([]);
  const [dataMapView, setDataMapView] = useState(true);
  const [selectedApp, setSelectedApp] = useState({});

  const [selectedAppByType, setSelectedAppByType] = useState({});

  const [bottomTrayEnable, setBottomTrayEnable] = useState(false);
  const [viewType, setViewType] = React.useState("");
  const [selectedNodeName, setSelectedNodeName] = useState("");
  const [creationTime, setCreationTime] = useState("");
  const [dataSource, setDataSource] = useState("");
  const [toolTipData, setToolTipData] = useState({});
  const [toolTipDataPoint, setToolTipDataPoint] = useState({});
  const [activeTab, setActiveTab] = useState("Data Assets");
  const [locationData, setLocationData] = useState(null);
  

  const dispatch = useDispatch();
  const handleSelectedNode = (node, name, subType, nodeCreationTime, sourceName, nodeToolTipData, tooltipData) => {
    dispatch({
      type: "SELECTED_NODE",
      payload: { node: node, subtype: subType },
    });
    dispatch({
      type: SET_ALL_NODE_DETAILS,
      payload: { node: node, subtype: subType, name: name, creationTime: nodeCreationTime, sourceName: sourceName,
      toolTipData: tooltipData },
    })
    setSelectedNodeName(name);
    setCreationTime(nodeCreationTime);
    setDataSource(sourceName);
    setToolTipData(nodeToolTipData);
    setToolTipDataPoint(tooltipData);
  };

  const filterAppByNameHandle = (app) => {
    setSelectedApp(app);
    const dimensionType = ["nodeId", "nodeName", "nodeType"];
    let filtersList = [];
    if (app && app.id) {
      filtersList = [
        {
          dimensionName: "NodesOfApplication",
          dimensionType: "applicationId",
          operator: "equals",
          values: [app.id],
        },
      ];
    }

    const queryString = cubeQueryGen(
      "NodesOfApplication",
      dimensionType,
      filtersList
    );

    dispatch({
      type: CLEAR_DATA_MAP,
    });

    dispatch({
      type: SET_DATAMAP_LOADER,
      payload: true,
    });

    dispatch({
      type: GET_NODES_LIST,
      payload: {
        cubejsApi: cubejsApi,
        queryString: queryString,
        filterId: FILTER_ID
      }
    });
  };

  const selectAppTableView = (eve) => {
    let name = "";
    if (eve.target.value === "alerts") {
      name = "Alerts";
      history.push("alerts");
    } else if (eve.target.value === "alertPolicies") {
      name = "Alert Policies";
      history.push("alertPolicies");
    } else if (eve.target.value === "dataFlows") {
      name = "Data Flows";
      history.push("dataFlows");
    } else if (eve.target.value === "dataFlowGroups") {
      name = "Data Flow Groups";
      history.push("dataFlowGroups");
    } else if (eve.target.value === "contents") {
      name = "Contents";
    } else {
      name = "Traces";
    }
    setSelectedAppByType({
      type: eve.target.value,
      id: "",
      tabIndex: true,
      display: name,
    });
    tableViewClick(false);
  };

  const [showUsers, setShowUsers] = useState(false);
  const [editCriteria, setEditCriteria] = useState(false);

  const toggleChecked = () => {
    setShowUsers((prev) => !prev);
  };

  const handleEditCriteria = () => {
    setEditCriteria((prev) => !prev)
  }
  useEffect(() => {
    dispatch({
      type: CLEAR_DATA_MAP,
    });
    dispatch({
      type: GET_LOCATION_DATA,
    });
  }, []);

  useEffect(() => {
    if (location.state && location.state.type) {
      if (location.state.type === "dataFlowGroups") {
        setSelectedAppByType(location.state);
        tableViewClick(false);
      } else if (location.state.type === "dataFlows") {
        setSelectedAppByType(location.state);
        tableViewClick(false);
      }
    }
    return () => {
      // Unmounting data map
      const currentRoute = window.location.pathname;
      if(currentRoute !== DATA_MAP_URL) {
        dispatch({
          type: CLEAR_DATAMAP_MULTI_TOOLTIP,
        });
      }
    };
  }, [location, selectedDateTimeRange]);

  const tableViewClick = (val) => {
    setBottomTrayEnable(false);
    setDataMapView(val);
  };

  const bottomTrayEnableEve = (val) => {
    setBottomTrayEnable(val);
    dispatch({
      type: CLEAR_DATAMAP_MULTI_TOOLTIP,
    });
  };

  useEffect(() => {
    bottomTrayEnableEve(false)
  },[activeTab])
  const needs = [
    {
      value: 'alerts',
      key: 'alerts',
      title: 'Alerts',
      permission: viewAlert,
    },
    {
      value: 'alertPolicies',
      key: 'alertPolicies',
      title: 'Policies',
      permission: viewAlertPolicy,
    },
    {
      value: 'dataFlows',
      key: 'dataFlows',
      title: 'Data Flows',
      permission: viewDataFlow,
    },
    {
      value: 'dataFlowGroups',
      key: 'Alerts',
      title: 'Flow Groups',
      permission: viewDataFlowGroup,
    },
  ];
  const menuItems = () => {
    return needs
      .filter((need) => permissions.includes(need.permission))
      .map((need) => (
        <MenuItem value={need.value} key={need.key}>
          {need.title}
        </MenuItem>
      ));
  };

  return (
    <div className="datamap-container-box">
      {!dataMapView ? (
        <Grid container className="text1"></Grid>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="datamap-container">
              <div className="datamap-header">
                <div className="datamap-header-content">
                  <div className="search-text-box">
                    <div className={classes.search}></div>
                  </div>
                  <div className="right-content">
                    <CanAllow needs={{ permission: [VIEW_ENTERPRISE_ACTORS] }}>
                      {activeTab === "Data Assets" ? (
                        <>
                          <span style={{ paddingRight: "10px" }}>
                            Show Users
                          </span>
                          <FormControlLabel
                            control={
                              <Switch
                                showUsers={showUsers}
                                onChange={toggleChecked}
                                color="primary"
                              />
                            }
                            label=""
                          />
                          <ColorButtonForClearFilter
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleEditCriteria}
                            disabled={!showUsers}
                          >
                            Edit Criteria
                          </ColorButtonForClearFilter>
                          {showUsers && editCriteria && (
                            <EditCriteria
                              showCriteria={editCriteria}
                              handleEditCriteria={handleEditCriteria}
                            />
                          )}
                          <div
                            className="filter-text-box"
                            style={{ marginLeft: "8px" }}
                          >
                            <div className="filter-sec">Filter</div>
                            <div className="filter-text-input">
                              <FilterAppByName
                                filterAppByNameHandle={filterAppByNameHandle}
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                    </CanAllow>
                    <div className="table-view-graph">
                      <span className="table-view-btn">
                        <span className="icon-box">
                          <img
                            src={TableListIcon}
                            className="table-list-icon"
                            alt="logo"
                          />
                          <span className="icon-box-text">Table View</span>
                          <span className="icon-box-drop">
                            <FormControl>
                              <InputLabel id="table-select-label"></InputLabel>
                              <Select
                                labelId="table-select-label"
                                id="table-select"
                                value={viewType}
                                onChange={(e) => selectAppTableView(e)}
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                              >
                                {menuItems()}
                              </Select>
                            </FormControl>
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="dataSelectionContainer">
                  <ManageDataMap setActiveTab={setActiveTab} />
                </div>
                {activeTab === "Data Assets" ? (
                  <div className="datamap-breadcrumb">
                    <Breadcrumbs aria-label="breadcrumb" className="">
                      <Link
                        color="inherit"
                        href="javascript:void(0)"
                        onClick={handleClick}
                      >
                        {selectedApp && selectedApp.name ? (
                          <span className="">{selectedApp.name}</span>
                        ) : (
                          <span className="">All Applications</span>
                        )}
                      </Link>
                      <Typography color="textPrimary"></Typography>
                    </Breadcrumbs>
                  </div>
                ) : null}
              </div>
              {activeTab === "Data Assets" ? (
                <ShowDataMap
                  handleSelectedNode={handleSelectedNode}
                  bottomTrayEnableEve={bottomTrayEnableEve}
                  showUsers={showUsers}
                />
              ) : (
                <>
                  <LocationMap
                    bottomTrayEnableEve={bottomTrayEnableEve}
                    locationDataInspect={setLocationData}
                  />
                </>
              )}
            </div>
          </Grid>
        </Grid>
      )}
      <div
        className={
          bottomTrayEnable
            ? "bottomTrayEnable bottomTrayEnableView"
            : "bottomTrayEnable"
        }
      >
        <InspectManager
          nodeName={selectedNodeName}
          creationTime={creationTime}
          dataSource={dataSource}
          selectedAppByType={selectedAppByType}
          selectedApp={selectedApp}
          selectedAlertIds={selectedAlertIds}
          bottomTrayEnable={bottomTrayEnable}
          bottomTrayEnableEve={bottomTrayEnableEve}
          tooltipData={toolTipDataPoint}
          activeTab={activeTab}
          locationData={locationData}
        />
      </div>
    </div>
  );
}